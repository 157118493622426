import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IHabitationalApplication } from "./habitationalApplicationNew.types";

export const FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS =
  "FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS";
export const FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS =
  "FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS";
export const FETCH_HABITATIONAL_APPLICATION_LIST_FAILED =
  "FETCH_HABITATIONAL_APPLICATION_LIST_FAILED";

export const fetchHabitationalApplicationListProgress = () =>
  action(FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS);
export const fetchHabitationalApplicationListSuccess = (
  list: IHabitationalApplication[],
  totalRecords: number
) =>
  action(FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS, { list, totalRecords });
export const fetchHabitationalApplicationListFailed = () =>
  action(FETCH_HABITATIONAL_APPLICATION_LIST_FAILED);

export const fetchHabitationalApplicationListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalApplicationListProgress());
      const res = await api.get(
        `/policy/get-policy-habitational-application?customer_policy_id=${customerPolicyId}`
      );
      const data: IHabitationalApplication[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      console.log("data from list view", data);
      dispatch(fetchHabitationalApplicationListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchHabitationalApplicationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_APPLICATION_PROGRESS =
  "FETCH_HABITATIONAL_APPLICATION_PROGRESS";
export const FETCH_HABITATIONAL_APPLICATION_SUCCESSS =
  "FETCH_HABITATIONAL_APPLICATION_SUCCESSS";
export const FETCH_HABITATIONAL_APPLICATION_FAILED =
  "FETCH_HABITATIONAL_APPLICATION_FAILED";

export const fetchHabitationalApplicationProgress = () =>
  action(FETCH_HABITATIONAL_APPLICATION_PROGRESS);
export const fetchHabitationalApplicationSuccess = (
  data: IHabitationalApplication
) => action(FETCH_HABITATIONAL_APPLICATION_SUCCESSS, { data });
export const fetchHabitationalApplicationFailed = (error?: string) =>
  action(FETCH_HABITATIONAL_APPLICATION_FAILED, { error });

export const fetchHabitationalApplicationAsync =
  (
    customerPolicyId: number,
    IncrementalNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalApplicationProgress());
      const res = await api.get(
        `/policy/get-policy-habitational-application?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${IncrementalNumber}`
      );

      const data: IHabitationalApplication[] = res.data.data;
      if (data.length > 0) {
        const firstElement: IHabitationalApplication = data[0];

        dispatch(fetchHabitationalApplicationSuccess(firstElement));
      } else {
        dispatch(
          fetchHabitationalApplicationFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalApplicationFailed("Some error occured"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalApplication =
  (
    data: IHabitationalApplication,
    customerPolicyId: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-policy-habitational-application", {
        ...data,
        customer_policy_id: customerPolicyId,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Habitational application data saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchHabitationalApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_HABITATIONAL_APPLICATION_STATE =
  "CLEAR_HABITATIONAL_APPLICATION_STATE";
export const clearHabitationalApplicationState = () =>
  action(CLEAR_HABITATIONAL_APPLICATION_STATE);
