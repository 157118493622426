/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Grid, Typography } from "@mui/material";
import { FileUpload } from "../../components/FileUpload/FileUpload";
import { LoadState } from "../../constants/enums";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import xml from "react-syntax-highlighter/dist/esm/languages/hljs/xml";

interface ManageCSIOProps {
  values: any;
  rawDataLoading: any;
}

export const ManageCSIO: React.FC<ManageCSIOProps> = ({
  values,
  rawDataLoading,
}) => {

  return (
    <PageLoader loading={rawDataLoading === LoadState.InProgress}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} lg={4}>
          <Typography variant="h5" fontWeight={800}>
            Message GUID
          </Typography>

          <Typography
            id="message_guid"
            variant="body1"
            fontWeight={500}
            fontSize={"1rem"}
          >
            {values.message_guid}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography variant="h5" fontWeight={800}>
            From Email
          </Typography>

          <Typography
            id="from_email_address"
            variant="body1"
            fontWeight={500}
            fontSize={"1rem"}
            color={"primary.main"}
          >
            {values.from_email_address}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography variant="h5" fontWeight={800}>
            Message Type
          </Typography>

          <Typography
            id="message_type"
            variant="body1"
            fontWeight={500}
            fontSize={"1rem"}
          >
            {values.message_type}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="h5" fontWeight={800}>
            Subject
          </Typography>
          <Typography
            id="message_subject"
            variant="body1"
            fontWeight={500}
            fontSize={"1rem"}
          >
            {values.message_subject}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={800}>
            Attachment XML
          </Typography>

          <SyntaxHighlighter language={xml} style={docco}>
            {values.xml_message}
          </SyntaxHighlighter>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography variant="h5" fontWeight={800}>
            Attachments EDOCS
          </Typography>
          {values.attachment ? (
            <FileUpload disabled value={values.attachment} deleteDisabled />
          ) : (
            <Typography variant="body1" color="textSecondary">
              No attachment found
            </Typography>
          )}
        </Grid>
      </Grid>
    </PageLoader>
  );
};
