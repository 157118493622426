/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../components/formsComponents";
import { useDispatchWrapper } from "../../hooks";

import { useNavigate } from "react-router-dom";
import { Dialog } from "../../components/Dialogs/Dialog";
import {
  addInitializeMappingAsync,
  fetchFromCSIOListAsync,
} from "../../redux/csio/csioActions";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../components/formsComponents/CustomDatePicker";
import { ICSIORaw } from "../../redux/csio/csio.types";
import moment from "moment";

export const CSIOSendReceive: React.FC<{
  open: boolean;
  onClose: () => void;
}> = (props) => {
  const { open, onClose } = props;

  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({});
  const navigate = useNavigate();

  const { values, handleChange, handleSubmit, setValues, errors } = useFormik({
    initialValues: {
      from_date: "",
      from_time: "",
      to_date: "",
      to_time: "",
    },
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      const fromTimePart = moment(values.from_time).format("HH:mm:ss");
      const combinedFromDateTime = moment(
        `${values.from_date} ${fromTimePart}`,
        "YYYY-MM-DDTHH:mm:ss.SSS"
      ).format(  "YYYY-MM-DDTHH:mm:ss.SSS")+"Z";


      const toTimePart = moment(values.to_time).format("HH:mm:ss");
      const combinedToDateTime = moment(
        `${values.to_date} ${toTimePart}`,
       "YYYY-MM-DDTHH:mm:ss.SSS"
      ).format("YYYY-MM-DDTHH:mm:ss.SSS")+"Z";
      // if (values) {
      //   setLoading(true);
      //   dispatch(
      //     addInitializeMappingAsync(
      //       "",
      //       values.from_date,
      //       utcDateTime,
      //       (isSuccess) => {
      //         // if (isSuccess) {
      //         //   onComplete();
      //         // }
      //         setLoading(false);
      //       }
      //     )
      //   );
      // }

      dispatch(
        fetchFromCSIOListAsync(combinedFromDateTime,combinedToDateTime,(newCode) => {
          navigate("/csio-progress/" + newCode);
        })
      );
    },
  });

  return (
    <>
      <Dialog
        open={open}
        title="Send/Receive"
        onClose={onClose}
        size="sm"
        contentWrappedWithForm={{ onSubmit: handleSubmit }}
        actions={[
          { type: "button", label: "Close", variant: "text", onClick: onClose },
          {
            type: "submit",
            label: "Run",
            variant: "contained",
            disabled: loading,
          },
        ]}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <CustomFormLabel>From Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.from_date || ""}
              onChange={(newValue) => {
                setValues({ ...values, from_date: newValue });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomFormLabel>Time</CustomFormLabel>
            <CustomTimePicker
              fullWidth
              value={values.from_time || ""}
              onChange={(newValue) => {
                setValues({ ...values, from_time: newValue });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={6}>
            <CustomFormLabel>To Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.to_date || ""}
              onChange={(newValue) => {
                setValues({ ...values, to_date: newValue });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomFormLabel>Time</CustomFormLabel>
            <CustomTimePicker
              fullWidth
              value={values.to_date || ""}
              onChange={(newValue) => {
                setValues({ ...values, to_time: newValue });
              }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
