import React from "react";
import { IPolicy } from "../views/Policies/Policies.types";
import { api } from "../api/api";
import { useDispatchWrapper } from "./useDispatch";
import { showMessage } from "../redux/messages/messagesActions";
import { ICustomerFullDetails } from "../redux/customer/customer.types";

export const useBusinessTrackerCustomerPolicies = () => {
  const [policies, setPolicies] = React.useState<IPolicy[]>([]);
  const dispatch = useDispatchWrapper();

  const getCustomerDetails = async (customerId: number) => {
    try {
      const res = await api.get(
        `/customer/get-customer-all-detailed-record?customer_id=${customerId}`
      );
      const data: ICustomerFullDetails[] = res.data.data;
      if (data.length > 0) {
        const customerInfo = data[0];
        return customerInfo;
      }
      return null;
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      return null;
    }
  };

  const fetchPolicies = async (customerId: number) => {
    try {
      const res = await api.get(
        `/policy/get-all-customer-policy-details?customer_id=${customerId}`
      );
      const policiesList: IPolicy[] = res.data.data || [];
      setPolicies(policiesList);
      return policiesList;
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      return [];
    }
  };

  const getPolicyFullDetails = (
    customerPolicyId: number,
    policiesList?: IPolicy[]
  ) => {
    const finalPoliciesList = policiesList ? policiesList : policies;
    const policy = finalPoliciesList.find(
      (x) => x.customer_policy_id === customerPolicyId
    );

    return policy;
  };

  const clearPolicies = () => {
    setPolicies([]);
  };

  return {
    policies,
    fetchPolicies,
    getPolicyFullDetails,
    clearPolicies,
    getCustomerDetails
  };
};
