import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { fetchBusinessPolicySubscriptionListAsync } from "../../../../../../redux/businessPolicy/businessPolicySubscription/businessPolicySubscriptionActions";
import { IBusinessPolicySubscription } from "../../../../../../redux/businessPolicy/businessPolicySubscription/businessPolicySubscription.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";

export const SubscriptionList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.subscription
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchBusinessPolicySubscriptionListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const subscriptionProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/subscription`
                  );
                }}
              >
                Add Subscription
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "increment_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicySubscription) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/subscription/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "insurer_or_subscriber",
        headerName: "Insurer/Subscriber",
        fieldName: "insurer_or_subscriber",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
        onRowCellRender: (value, row: IBusinessPolicySubscription) => {
          return (
            <CustomLink
              to={`/business-policy/${customerId}/schedule-tab/${customerPolicyId}/subscription/${row.incremental_number}`}
              label={value.value}
            />
          );
        },
      },
      {
        key: "premium_amount",
        headerName: "Premium ($)",
        fieldName: "premium_amount",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },

      {
        key: "schedule_or_line_of_coverage",
        headerName: "Schedule/Line of Coverage",
        fieldName: "schedule_or_line_of_coverage",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "subscription_percentage",
        headerName: "Subscription%",
        fieldName: "subscription_percentage",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },

      {
        key: "expiry_date",
        headerName: "Expiry Date",
        fieldName: "expiry_date",
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 30,
      },
      {
        key: "insert_ts",
        headerName: "Date Created",
        fieldName: "insert_ts",
        renderType: RenderType.DATE,
        exportCellWidth: 30,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicySubscription) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/subscription/${row.incremental_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...subscriptionProps} />
      </Box>
    </>
  );
};
