/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CustomerBaiscDetailsLayout } from "../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { StandadCard } from "../../../../../components/Cards";
import { Box } from "@mui/material";
import { PolicyTabCard } from "../../../../../components/PolicyTabCard/PolicyTabCard";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { BreadcrumbProvider } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { fetchHabitationalCustomerInfoAsync } from "../../../../../redux/habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsActions";
import { clearNotesState } from "../../../../../redux/notes/notesActions";
import { batch } from "react-redux";

export const HabitationalPolicyLayout: React.FC = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const {
    customerPoliycId: savedCustomerPolicyId,
    customerInfo: { loading, error },
  } = useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  const location = useLocation();
  const currentPathname = location.pathname;
  const isMainTab = currentPathname.includes("basic-details");

  const handleStep = (step: string) => () => {
    const isAlreadyInSamePage = currentPathname.includes(step);
    if (!isAlreadyInSamePage) {
      navigate(
        `/habitational-policy/${customerId}/${step}/${savedCustomerPolicyId}?tab=0`
      );
    }
  };

  const renderComponent = () => {
    if (isMainTab) {
      return <Outlet />;
    } else if (!isMainTab && !customerPolicyId) {
      return <Navigate to={`/customer-overview/${customerId}`} />;
    } else if (!isMainTab && savedCustomerPolicyId) {
      return <Outlet context={[customerId, savedCustomerPolicyId]} />;
    } else {
      console.log("__FALIED____");
      return null;
    }
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchHabitationalCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);
  React.useEffect(() => {
    return () => {
      batch(() => {
        dispatch(clearNotesState());
      });
    };
  }, []);

  return (
    <>
      <BreadcrumbProvider>
        <CustomerBaiscDetailsLayout customerId={customerId} fromPolicy policyId={customerPolicyId}>
          <StandadCard sx={{ my: 2 }}>
            <Box display={"flex"} flexWrap={"wrap"}>
              <PolicyTabCard
                label="Basic Details"
                onClick={handleStep("basic-details")}
                isSelected={currentPathname.includes("basic-details")}
              />

              <PolicyTabCard
                label="Claims"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("claims-tab")}
                isSelected={currentPathname.includes("claims-tab")}
              />
              <PolicyTabCard
                label="Risk Location"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("risk-location-tab")}
                isSelected={currentPathname.includes("risk-location-tab")}
              />
              <PolicyTabCard
                label="Coverage"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("coverage-tab")}
                isSelected={currentPathname.includes("coverage-tab")}
              />

              <PolicyTabCard
                label="Application"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("application-tab")}
                isSelected={currentPathname.includes("application-tab")}
              />
              <PolicyTabCard
                label="CSIO Application"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("habitational-application-csio-tab")}
                isSelected={currentPathname.includes("habitational-application-csio-tab")}
              />

              <PolicyTabCard
                label="Binder"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("binder-csio-tab")}
                isSelected={currentPathname.includes("binder-csio-tab")}
              />

              <PolicyTabCard
                label="Policy Forms"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-forms-tab")}
                isSelected={currentPathname.includes("policy-forms-tab")}
              />
              {/* <PolicyTabCard
                label="Activity"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("activity-tab")}
                isSelected={currentPathname.includes("activity-tab")}
              /> */}
              <PolicyTabCard
                label="History"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("history-tab")}
                isSelected={currentPathname.includes("history-tab")}
              />
              <PolicyTabCard
                label="Attachments"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("attachments-tab")}
                isSelected={currentPathname.includes("attachments-tab")}
              />
              <PolicyTabCard
                label="Abeyance/Notes"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("notes-tab")}
                isSelected={currentPathname.includes("notes-tab")}
              />
              <PolicyTabCard
                label="Business Tracker"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("task-activity-tab")}
                isSelected={currentPathname.includes("task-activity-tab")}
              />
               <PolicyTabCard
                label="eDocs"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("csio-edocs-list")}
                isSelected={currentPathname.includes("csio-edocs-list")}
              />
              
              <PolicyTabCard
                label="Policy Change"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-change-request")}
                isSelected={currentPathname.includes("policy-change-request")}
              />
            </Box>
          </StandadCard>
          <PageLoader
            loading={loading === LoadState.InProgress}
            error={error ? { message: error } : null}
          >
            {renderComponent()}
          </PageLoader>
        </CustomerBaiscDetailsLayout>
      </BreadcrumbProvider>
    </>
  );
};
