import {
  AUTOMOBILE_CSIO_BILLING_METHOD,
  AUTOMOBILE_CSIO_BOOLEAN_MATCH,
  AUTOMOBILE_CSIO_COVERAGE,
  AUTOMOBILE_CSIO_COVERAGE_CLAIMS,
  AUTOMOBILE_CSIO_ENGINE,
  AUTOMOBILE_CSIO_GENDER,
  AUTOMOBILE_CSIO_LOSS,
  AUTOMOBILE_CSIO_MERTIAL_STATUS,
  AUTOMOBILE_CSIO_NEWUSED,
  AUTOMOBILE_CSIO_OTHER_COVERAGE,
  AUTOMOBILE_CSIO_PAYMENT_COVRAGE,
  AUTOMOBILE_CSIO_VEHCILE_BODY_TYPE,
  AUTOMOBILE_CSIO_VEHICLE_USE,
  CSIO_POLICY_NAME,
} from "../../constants/constants";
import { getUniqueId } from "../../helpers";
import {
  IAutoDescribedSectionFour,
  IAutoDescribedSectionOne,
  IAutoDescribedSectionThree,
  IAutoDescribedSectionTwo,
  IAutoDriverInformationOne,
  IAutoDriverInformationTwo,
  IAutoInsuranceApplied,
  IAutomobileApplication,
  IAutoPreviousClaims,
  IAutoRatingInformationOne,
  IAutoRatingInformationTwo,
} from "../automobileApplication/automobileApplication.types";
import { IAutomobilePremiumPaymentSchedule } from "../automobileBasicDetails/automobileBasicDetails.types";
import { ICommAutoCSIOHistory } from "../commAutoPolicy/commAutoCsioApplication/commAutoCsioApplication.types";

export const transformCSIOToAutomobile = (
  parsedJSON: any
): IAutomobileApplication => {
  const persAutoPolicyAddRs =
    parsedJSON.ACORD?.InsuranceSvcRs?.PersAutoPolicyAddRs ||
    parsedJSON.ACORD?.InsuranceSvcRs?.PersAutoPolicyModRs;
  const {
    PersPolicy,
    Producer: { ProducerInfo },
    InsuredOrPrincipal,
  } = persAutoPolicyAddRs;

  let persVehcilesList = Array.isArray(
    persAutoPolicyAddRs?.PersAutoLineBusiness?.PersVeh
  )
    ? persAutoPolicyAddRs?.PersAutoLineBusiness?.PersVeh
    : [persAutoPolicyAddRs?.PersAutoLineBusiness?.PersVeh];

  persVehcilesList = persVehcilesList.sort((a: any, b: any) => {
    const idA = parseInt(a?.["csio:PCVEH"]?.ItemIdInfo?.InsurerId, 10);
    const idB = parseInt(b?.["csio:PCVEH"]?.ItemIdInfo?.InsurerId, 10);
    return idA - idB;
  });

  //Applicant details
  const customerDetails = Array.isArray(InsuredOrPrincipal)
    ? InsuredOrPrincipal
    : [InsuredOrPrincipal];

  const applicantDetails = {
    name: "",
    address: "",
    address_two: "",
    country: "",
    city: "",
    state: "",
    postalcode: "",
    phone: "",
    mobile: "",
  };
  const names: any[] = [];
  customerDetails.forEach((detail: any) => {
    const nameInfo = detail?.GeneralPartyInfo?.NameInfo;
    const commercialName = nameInfo?.CommlName;
    const personName = nameInfo?.PersonName;
    if (personName) {
      const fullName = `${personName?.GivenName} ${personName?.Surname}`.trim();
      names.push(fullName);
    }
    if (commercialName) {
      names.push(commercialName?.CommercialName);
    }

    const addr = detail?.GeneralPartyInfo?.Addr;
    if (addr) {
      applicantDetails.address = addr?.Addr1;
      applicantDetails.address_two = addr?.Addr2;
      applicantDetails.city = addr?.City;
      applicantDetails.state = addr?.StateProvCd;
      applicantDetails.postalcode = addr?.PostalCode;
      applicantDetails.country = addr?.CountryCd;
    }

    const phoneInfo = Array.isArray(
      detail?.GeneralPartyInfo?.Communications?.PhoneInfo
    )
      ? detail?.GeneralPartyInfo?.Communications?.PhoneInfo
      : [detail?.GeneralPartyInfo?.Communications?.PhoneInfo];
    phoneInfo.forEach((phone: any) => {
      if (phone?.CommunicationUseCd === "Home") {
        applicantDetails.phone = phone?.PhoneNumber;
      }
      if (phone?.CommunicationUseCd === "Alternate") {
        applicantDetails.mobile = phone?.PhoneNumber;
      }
      if (phone?.CommunicationUseCd === "Business") {
        applicantDetails.phone = phone?.PhoneNumber;
        applicantDetails.mobile = phone?.PhoneNumber;
      }
    });
  });

  applicantDetails.name = names.join(" & ");

  //Vehicles ------------------>

  const describedSectionOne: IAutoDescribedSectionOne[] = [];
  const describedSectionTwo: IAutoDescribedSectionTwo[] = [];
  const describedSectionThree: IAutoDescribedSectionThree[] = [];
  const describedSectionFour: IAutoDescribedSectionFour[] = [];
  const vehiclesQuestionAnswers: {
    QuestionCd: string;
    YesNoCd: string;
  }[] = [];

  for (const vehcile of persVehcilesList) {
    const vehiclePCVEH = vehcile?.["csio:PCVEH"];

    // Section 1 ------------------------------>
    const grossVehicleWeight =
      vehiclePCVEH?.GrossVehWeight?.NumUnits +
      " " +
      vehiclePCVEH?.GrossVehWeight?.UnitMeasurementCd;
    const bodyTypeValue = vehiclePCVEH?.VehBodyTypeCd || "";
    const bodyTypeLabel = AUTOMOBILE_CSIO_VEHCILE_BODY_TYPE[bodyTypeValue];

    describedSectionOne.push({
      key: vehcile.$.id || "",
      autoNo: vehiclePCVEH?.ItemIdInfo?.InsurerId || "",
      modelYear: vehiclePCVEH?.ModelYear || "",
      bodyType: bodyTypeLabel || "",
      grossVehicleWeight: grossVehicleWeight || "",
      makeTradeName: vehiclePCVEH?.Manufacturer || "",
      model: vehiclePCVEH?.Model || "",
      noCylinders: null,
    });

    // Section 2 -------------------------->
    const numUnits = vehiclePCVEH?.DistanceOneWay?.NumUnits || "";
    const measuredUnits = vehiclePCVEH?.DistanceOneWay?.UnitMeasurementCd || "";
    const distanceOneWay = `${numUnits} ${measuredUnits}`;

    const automobileUsedValue = vehiclePCVEH?.VehUseCd || "";
    const automobileUsedLabel =
      AUTOMOBILE_CSIO_VEHICLE_USE[automobileUsedValue];
    const ownedLeasedValue = vehiclePCVEH?.LeasedVehInd || "";

    const purchaseLeasedValue = vehiclePCVEH?.["csio:NewUsedCd"] || "";
    const purchaseLeasedLabel = AUTOMOBILE_CSIO_NEWUSED[purchaseLeasedValue];
    let owned: string | null = null;
    let leased: string | null = null;

    if (ownedLeasedValue === "0") {
      owned = "Yes";
      leased = "No";
    } else if (ownedLeasedValue === "1") {
      owned = "No";
      leased = "Yes";
    }

    describedSectionTwo.push({
      key: vehcile?.$?.id,
      autoNo: vehiclePCVEH?.ItemIdInfo?.InsurerId || "",
      vinNumber: vehiclePCVEH?.VehIdentificationNumber || "",
      owned: owned || "",
      leased: leased || "",
      purchaseLeasedDate: vehiclePCVEH?.PurchaseDt || "",
      purchaseLeased: purchaseLeasedLabel || "",
      purchasePrice: vehiclePCVEH?.PurchasePriceAmt?.Amt || "",
      automobileUsed: automobileUsedLabel || "",
      automobileUsedOneWay: distanceOneWay,
      automobileUsedPrecentage: vehcile?.Description || "",
    });

    // Section 3 ------------------------------------>
    const typefFuelUsedValue = vehiclePCVEH?.EngineTypeCd || "";
    const typefFuelUsedLabel = AUTOMOBILE_CSIO_ENGINE[typefFuelUsedValue];
    const unrepairedValue =
      vehiclePCVEH?.ExistingUnrepairedDamageInfo?.ExistingUnrepairedDamageInd ||
      "";
    const unrepairedLabel = AUTOMOBILE_CSIO_BOOLEAN_MATCH[unrepairedValue];
    const modifiedCustomisedValue = vehiclePCVEH?.AlteredInd || "";
    const modifiedCustomisedLabel =
      AUTOMOBILE_CSIO_BOOLEAN_MATCH[modifiedCustomisedValue];
    const carpoolIndValue = vehiclePCVEH?.CarpoolInd || "";
    const carpoolIndLabel = AUTOMOBILE_CSIO_BOOLEAN_MATCH[carpoolIndValue];

    describedSectionThree.push({
      key: vehcile?.$?.id || "",
      autoNo: vehiclePCVEH?.ItemIdInfo?.InsurerId || "",
      annualDrivingDistance:
        vehiclePCVEH?.EstimatedAnnualDistance?.NumUnits || "",
      automobileUsedForPooling: carpoolIndLabel || "",
      automobileUsedForPoolingIfYes: vehiclePCVEH?.NumPassengers || "",
      typefFuelUsed: typefFuelUsedLabel || "",
      typefFuelUsedIfYes: vehiclePCVEH?.EngineTypeCd || "",
      unrepairedDamage: unrepairedLabel || "",
      modifiedCustomized: modifiedCustomisedLabel || "",
    });

    // Section 4 --------------------------------->
    const lienholderName =
      vehiclePCVEH?.AdditionalInterest?.GeneralPartyInfo?.NameInfo?.PersonName
        ?.GivenName || "";
    const lienholderSurname =
      vehiclePCVEH?.AdditionalInterest?.GeneralPartyInfo?.NameInfo?.PersonName
        ?.Surname || "";

    // Commercial name if provided
    const lienholderCommercialName =
      vehiclePCVEH?.AdditionalInterest?.GeneralPartyInfo?.NameInfo?.CommlName
        ?.CommercialName || "";

    const lienholderFullName =
      lienholderName || lienholderSurname
        ? `${lienholderName} ${lienholderSurname}`.trim()
        : lienholderCommercialName;

    const Address =
      vehiclePCVEH?.AdditionalInterest?.GeneralPartyInfo?.Addr || {};

    const addr1 = Address.Addr1 || "";
    const city = Address.City || "";
    const countryCd = Address.CountryCd || "";
    const postalCode = Address.PostalCode || "";
    const stateProvCd = Address.StateProvCd || "";

    const postalAddress =
      `${addr1} ${city} ${countryCd} ${postalCode} ${stateProvCd}`.trim();
    describedSectionFour.push({
      key: vehcile?.$?.id || "",
      autoNo: vehiclePCVEH?.ItemIdInfo?.InsurerId || "",
      lienholderName: lienholderFullName,
      postalAddress: postalAddress,
    });

    vehiclesQuestionAnswers.push(vehcile.QuestionAnswer);
  }
  console.log("vehicle", vehiclesQuestionAnswers);

  //Drivers info -------------------------->

  let persDriversList = Array.isArray(
    persAutoPolicyAddRs?.PersAutoLineBusiness?.PersDriver
  )
    ? persAutoPolicyAddRs?.PersAutoLineBusiness?.PersDriver
    : [persAutoPolicyAddRs?.PersAutoLineBusiness?.PersDriver];

  persDriversList = persDriversList.sort((a: any, b: any) => {
    const idA = parseInt(a?.ItemIdInfo?.InsurerId, 10);
    const idB = parseInt(b?.ItemIdInfo?.InsurerId, 10);
    return idA - idB;
  });

  const driverInformation1: IAutoDriverInformationOne[] = [];
  const driverInformation2: IAutoDriverInformationTwo[] = [];
  let driverQuestionAnswers: {
    QuestionCd: string;
    YesNoCd: string;
  }[] = [];

  for (const driver of persDriversList) {
    const Name =
      driver?.GeneralPartyInfo?.NameInfo?.PersonName?.GivenName || "";
    const Surname =
      driver?.GeneralPartyInfo?.NameInfo?.PersonName?.Surname || "";
    const FullName = `${Name} ${Surname}`;

    const sexValue = driver?.DriverInfo?.PersonInfo?.GenderCd || "";
    const sexLabel = AUTOMOBILE_CSIO_GENDER[sexValue];
    const maritalStatusValue =
      driver?.DriverInfo?.PersonInfo?.MaritalStatusCd || "";
    const maritalStatusLabel =
      AUTOMOBILE_CSIO_MERTIAL_STATUS[maritalStatusValue];

    let driverLicenseNumber = "";
    const license = driver?.DriverInfo?.License;
    if (Array.isArray(license)) {
      driverLicenseNumber =
        license[1]?.LicensePermitNumber ||
        license[0]?.LicensePermitNumber ||
        "";
    } else if (typeof license === "object" && license !== null) {
      driverLicenseNumber = license.LicensePermitNumber || "";
    }
    driverInformation1.push({
      key: driver?.$?.id,
      driverNo: driver?.ItemIdInfo?.InsurerId || "",
      nameofDriver: FullName || "",
      dateOfBirth: driver?.DriverInfo?.PersonInfo?.BirthDt || "",
      driverLicenseNumber,
      maritalStatus: maritalStatusLabel || "",
      sex: sexLabel || "",
    });

    driverInformation2.push({
      key: driver?.$?.id,
      driverNo: driver?.ItemIdInfo?.InsurerId || "",
      driverTrainingCertificateAttached:
        driver?.DriverInfo?.["csio:DriverTrainingCd"] || "",
      dateFirstLicencedInCanadaorUSClass:
        driver?.DriverInfo?.License?.[0]?.LicenseClassCd || "",
      dateFirstLicencedInCanadaorUSYear:
        driver?.DriverInfo?.License?.[1]?.FirstLicensedCurrentStateDt || "",
      otherClassofLicenseIfAnyClass:
        driver?.DriverInfo?.License?.[0]?.LicenseClassCd || "",
      otherClassofLicenseIfAnyYear:
        driver?.DriverInfo?.License?.[1]?.FirstLicensedCurrentStateDt || "",
      areAnyOtherPersonsInTheHousehold: getDescibedAutoMobileQuestionAnswers(
        persVehcilesList,
        "csio:44"
      ) || {
        questionCd: null,
        yesNoCd: null,
      },
      doAnyDriversQualifyForRetireeDiscount:
        getDescibedAutoMobileQuestionAnswers(persVehcilesList, "csio:34") || {
          questionCd: null,
          yesNoCd: null,
        },
      percentageUseByEachDriverAuto1: null,
      percentageUseByEachDriverAuto2: null,
      percentageUseByEachDriverAuto3: null,
    });

    const questionAnswers = Array.isArray(driver?.DriverInfo?.QuestionAnswer)
      ? driver?.DriverInfo?.QuestionAnswer
      : driver?.DriverInfo?.QuestionAnswer
      ? [driver?.DriverInfo?.QuestionAnswer]
      : [];

    driverQuestionAnswers = driverQuestionAnswers.concat(questionAnswers);
  }

  const persPolicyLossList = Array.isArray(PersPolicy?.Loss)
    ? PersPolicy.Loss
    : [PersPolicy?.Loss];

  const previous_accidents_and_insurance_claims: IAutoPreviousClaims[] = [];

  for (const previousLoss of persPolicyLossList) {
    const detailValue = previousLoss?.["csio:LossCauseCd"] || "";
    const detailLabel = AUTOMOBILE_CSIO_LOSS[detailValue];

    let coverageClaimPaidUnder = {
      bi: "",
      pd: "",
      Ab: "",
      dcpd: "",
      ua: "",
      col: "",
      comp: "",
    };
    let amountPaidOrEstimate =
      previousLoss?.LossPayment?.LossPaymentAmt?.Amt || "";
    let details = detailLabel || "";

    const coverages = Array.isArray(previousLoss?.LossPayment?.Coverage)
      ? previousLoss?.LossPayment?.Coverage
      : [];
    coverages?.forEach((coverage: any) => {
      const coverageCd = coverage?.CoverageCd || "";
      const coverageLabel = AUTOMOBILE_CSIO_COVERAGE_CLAIMS[coverageCd];
      switch (coverageCd) {
        case "csio:TPBI":
          coverageClaimPaidUnder.bi = coverageLabel;
          break;
        case "csio:TPPD":
          coverageClaimPaidUnder.pd = coverageLabel;
          break;
        case "csio:AB":
          coverageClaimPaidUnder.Ab = coverageLabel;
          break;
        case "csio:TPDC":
          coverageClaimPaidUnder.dcpd = coverageLabel;
          break;
        case "csio:UA":
          coverageClaimPaidUnder.ua = coverageLabel;
          break;
        case "csio:COL":
          coverageClaimPaidUnder.col = coverageLabel;
          break;
        case "csio:CMP":
          coverageClaimPaidUnder.comp = coverageLabel;
          break;
        default:
          break;
      }
    });

    for (const driver of persDriversList) {
      const driverId = driver?.ItemIdInfo?.InsurerId || "";

      for (const vehicle of persVehcilesList) {
        const vehId = vehicle?.["csio:PCVEH"]?.ItemIdInfo?.InsurerId || "";
        if (
          previousLoss?.$?.DriverRef === driver?.$?.id &&
          previousLoss?.$?.VehRef === vehicle?.$?.id
        ) {
          previous_accidents_and_insurance_claims.push({
            key: getUniqueId(),
            driverNo: driverId,
            autoNo: vehId,
            date: previousLoss?.LossDt || "",
            coverageClaimPaidUnder,
            amountPaidOrEstimate,
            details,
          });
        }
      }
    }

    // return {
    //   key: getUniqueId(),
    //   driverNo,
    //   autoNo,
    //   date,
    //   coverageClaimPaidUnder,
    //   amountPaidOrEstimate,
    //   details,
    // };
  }
  const history_of_convictions: ICommAutoCSIOHistory[] = [];

  const persPolicyAccidentViolation = Array.isArray(
    PersPolicy?.AccidentViolation
  )
    ? PersPolicy?.AccidentViolation
    : [PersPolicy?.AccidentViolation];

  for (const accidentViolation of persPolicyAccidentViolation) {
    const accidentCd = accidentViolation?.AccidentViolationCd || "";
    const accidentDt = accidentViolation?.AccidentViolationDesc || "";
    const accidentdetail = `${accidentCd} ${accidentDt}`;

    let driverNo = "";
    let details = "";
    let dateConvicted = "";

    for (const driver of persDriversList) {
      const driverId = driver?.ItemIdInfo?.InsurerId || "";

      if (accidentViolation?.$?.DriverRef === driver?.$?.id) {
        history_of_convictions.push({
          key: getUniqueId(),
          driverNo: driverId || "",
          dateConvicted: accidentViolation?.ConvictionDt || "",
          details: accidentdetail || "",
        });
      }
    }

    // return {
    //   key: getUniqueId(),
    //   dateConvicted,
    //   details,
    //   driverNo,
    // };
  }

  const ratingInformationOne: IAutoRatingInformationOne[] = [];
  const ratingInformationTwo: IAutoRatingInformationTwo[] = [];

  for (const vehicle of persVehcilesList) {
    const autoNo = vehicle?.["csio:PCVEH"]?.ItemIdInfo?.InsurerId || "";
    const classValue = vehicle?.["csio:PCVEH"]?.RateClassCd || "";

    let bi = "";
    let pd = "";
    let ab = "";
    let dcpd = "";
    let coll_ap = "";
    for (const driver of persDriversList) {
      const drivingRecord = driver?.DriverInfo?.DrivingRecordRatingInfo || [];
      for (const record of drivingRecord) {
        if (record?.["$"]?.VehRef === vehicle?.["$"]?.id) {
          switch (record?.CoverageCd) {
            case "csio:TPBI":
              bi = record?.DrivingRecCd || "";
              break;
            case "csio:TPPD":
              pd = record?.DrivingRecCd || "";
              break;
            case "csio:AB":
              ab = record?.DrivingRecCd || "";
              break;
            case "csio:TPDC":
              dcpd = record?.DrivingRecCd || "";
              break;
            case "csio:COL":
              coll_ap = record?.DrivingRecCd || "";
              break;
            default:
              break;
          }
        }
      }
    }

    ratingInformationOne.push({
      drivingRecord: "",
      key: getUniqueId(),
      autoNo: autoNo,
      class: classValue,
      bi: bi,
      pd: pd,
      ab: ab,
      dcpd: dcpd,
      coll_ap: coll_ap,
      driverNoPrimary: "",
      driverNoSceondary: "",
      atFaultClaimSurcharges: "",
      atFaultClaimPrecentage: "",
      convictionsSurcharges: "",
      convictionsPrecentage: "",
    });
    ratingInformationTwo.push({
      key: vehicle?.$?.id || "",
      autoNo: vehicle?.["csio:PCVEH"]?.ItemIdInfo?.InsurerId,
      listPriceView: vehicle?.["csio:PCVEH"]?.CostNewAmt?.Amt || "",
      vehicleCode: vehicle?.["csio:PCVEH"]?.VehSymbolCd || "",
      ratingGroupAb:
        vehicle?.["csio:PCVEH"]?.VehRateGroupInfo?.[0]?.RateGroup || "",
      ratinratingGroupDcpd:
        vehicle?.["csio:PCVEH"]?.VehRateGroupInfo?.[3]?.RateGroup || "",
      ratingGroupCollAp:
        vehicle?.["csio:PCVEH"]?.VehRateGroupInfo?.[1]?.RateGroup || "",
      ratingGroupcompSp:
        vehicle?.["csio:PCVEH"]?.VehRateGroupInfo?.[2]?.RateGroup || "",
      location: vehicle?.["csio:PCVEH"]?.StatisticalLocationCd || "",
      territory: vehicle?.["csio:PCVEH"]?.TerritoryCd,
      discountDescription: "",
      discountPrecentage: "",
      ratingGroupDcpd: "",
    });
  }

  const PersPolicyquestionAnswer = Array.isArray(PersPolicy?.QuestionAnswer)
    ? PersPolicy?.QuestionAnswer
    : [PersPolicy?.QuestionAnswer];
  const haveYouBoundRisk = PersPolicyquestionAnswer?.find(
    (qa: any) => qa.QuestionCd === "csio:58"
  );
  const newBusiness = PersPolicyquestionAnswer?.find(
    (qa: any) => qa.QuestionCd === "csio:01"
  );

  const typeOfPaymentValue = PersPolicy?.PaymentOption?.PaymentPlanCd || "";
  const typeOfPaymentLabel =
    AUTOMOBILE_CSIO_PAYMENT_COVRAGE[typeOfPaymentValue];

  const insuraceAppliedData: IAutoInsuranceApplied[] = [];
  const onlyPremimumList = [
    "csio:CATIM",
    "csio:CHHMB",
    "csio:DFB",
    "csio:DCB",
    "csio:IDB",
    "csio:UA",
  ];
  const columns: string[] = ["Section"];

  for (let i = 0; i < persVehcilesList.length; i++) {
    columns.push("Automobile" + (i + 1));
  }

  for (const coverageKey in AUTOMOBILE_CSIO_COVERAGE) {
    const isOnlyPremimum = onlyPremimumList.includes(coverageKey);
    const data = {
      key: getUniqueId(),
      code: coverageKey,
      label: AUTOMOBILE_CSIO_COVERAGE[coverageKey],
      description: "",
      isTypeOther: false,
      automobiles: {},
    };
    for (let i = 0; i < persVehcilesList.length; i++) {
      const automobileKey = "Automobile" + (i + 1);
      data.automobiles = {
        ...data.automobiles,
        isTypeOther: false,
        [automobileKey]: {
          limit: null,
          premimum: null,
          deductible: null,
          isOnlyPremimum: isOnlyPremimum,
          isTypeOther: false,
        },
      };
    }
    insuraceAppliedData.push(data);
  }
  for (let i = 0; i < persVehcilesList.length; i++) {
    const vehcile = persVehcilesList[i];
    const automobileKey = "Automobile" + (i + 1);
    const coverageList = vehcile.Coverage;
    if (coverageList.length > 0) {
      for (const coverage of coverageList) {
        const index = insuraceAppliedData.findIndex(
          (x) => x.code === coverage.CoverageCd
        );
        if (index > -1) {
          insuraceAppliedData[index] = {
            ...insuraceAppliedData[index],
            automobiles: {
              ...insuraceAppliedData[index].automobiles,
              [automobileKey]: {
                ...insuraceAppliedData[index].automobiles[automobileKey],
                limit: coverage.Limit?.FormatCurrencyAmt?.Amt,
                premimum: coverage?.CurrentTermAmt?.Amt || "",
                deductible: coverage?.Deductible?.FormatCurrencyAmt?.Amt || "",
              },
            },
          };
        } else {
          insuraceAppliedData.push({
            key: getUniqueId(),
            code: coverage.CoverageCd,
            label: AUTOMOBILE_CSIO_OTHER_COVERAGE[coverage.CoverageCd],
            description: "",
            isTypeOther: true,
            automobiles: {
              [automobileKey]: {
                limit: coverage.Limit?.FormatCurrencyAmt?.Amt,
                premimum: coverage?.CurrentTermAmt?.Amt || "",
                deductible: coverage?.Deductible?.FormatCurrencyAmt?.Amt || "",
                isOnlyPremimum: false,
              },
            },
          });
        }
      }
    }
  }

  const describedPaymentschedule: IAutomobilePremiumPaymentSchedule[] = [];
  const installmentInfo = Array.isArray(
    PersPolicy?.PaymentOption?.InstallmentInfo
  )
    ? PersPolicy?.PaymentOption?.InstallmentInfo
    : [PersPolicy?.PaymentOption?.InstallmentInfo];
  for (const installment of installmentInfo) {
    describedPaymentschedule.push({
      id: getUniqueId(),
      installment_number: installment?.InstallmentNumber,
      installment_amount: installment.InstallmentAmt?.Amt,
      installment_date: installment.InstallmentDueDt,
    });
  }

  return {
    customer_policy_id: null,
    incremental_number: null,
    policy_number_assigned: PersPolicy.PolicyNumber,
    replacing_policy_no: null,
    policy_period_effective_date: PersPolicy.ContractTerm.EffectiveDt,
    policy_period_effective_time: null,
    policy_period_expiry_date: PersPolicy.ContractTerm.ExpirationDt,
    policy_period_expiry_time: null,
    new_policy:
      CSIO_POLICY_NAME[PersPolicy.PolicyStatusCd] || PersPolicy.PolicyStatusCd,
    billing_method:
      AUTOMOBILE_CSIO_BILLING_METHOD[PersPolicy.BillingMethodCd] ||
      PersPolicy.BillingMethodCd,
    language_preferred: PersPolicy.LanguageCd,
    insurance: {
      insurance_name: PersPolicy["csio:CompanyCd"],
      insurance_address: null,
      insurance_state: null,
      insurance_unit_or_suite: null,
      insurance_city: null,
      insurance_country: null,
      insurance_zip: null,
    },
    broker: {
      broker_agent: null,
      broker_agent_address: null,
      broker_agent_city: null,
      broker_agent_state: null,
      broker_agent_country: null,
      broker_agent_unit_or_suite: null,
      broker_agent_zip: null,
      broker_code: ProducerInfo.ContractNumber,
      broker_sub_code: ProducerInfo.ProducerSubCode,
    },
    application_details: applicantDetails,
    lessor: {
      lessor_name: null,
      lessor_email: null,
      lessor_full_address: null,
      lessor_phone: null,
    },
    described_automobiles: {
      described_automobiles_part_one: describedSectionOne,
      described_automobiles_part_two: describedSectionTwo,
      described_automobiles_part_three: describedSectionThree,
      described_automobiles_part_four: describedSectionFour,

      owner_actual_owner_yesNoCd: getDescibedAutoMobileQuestionAnswers(
        vehiclesQuestionAnswers,
        "csio:43"
      ).yesNoCd,
      total_automobiles_in_business:
        PersPolicy?.PersApplicationInfo?.NumVehsInHousehold,
      rented_or_leased_to_other: getDescibedAutoMobileQuestionAnswers(
        vehiclesQuestionAnswers,
        "csio:41"
      ).yesNoCd,
      carry_passengers_for_compensation: getDescibedAutoMobileQuestionAnswers(
        vehiclesQuestionAnswers,
        "csio:37"
      ).yesNoCd,
      haul_a_trailer: getDescibedAutoMobileQuestionAnswers(
        vehiclesQuestionAnswers,
        "csio:75"
      ).yesNoCd,
      carry_explosives: getDescibedAutoMobileQuestionAnswers(
        vehiclesQuestionAnswers,
        "csio:38"
      ).yesNoCd,
    },
    driver_information: {
      section_one: driverInformation1,
      section_two: driverInformation2,
      insurance_company:
        PersPolicy?.OtherOrPriorPolicy?.PolicyCd ||
        PersPolicy?.OtherOrPriorPolicy?.[0]?.PolicyCd ||
        "",
      has_any_driver_license: getDescibedAutoMobileQuestionAnswers(
        driverQuestionAnswers,
        "csio:35"
      ).yesNoCd,
      has_any_insurance_company: getDescibedAutoMobileQuestionAnswers(
        driverQuestionAnswers,
        "csio:23"
      ).yesNoCd,
      during_the_last_three_years: getDescibedAutoMobileQuestionAnswers(
        driverQuestionAnswers,
        "csio:46"
      ).yesNoCd,
      has_the_applicant_or_any_listed_driver:
        getDescibedAutoMobileQuestionAnswers(driverQuestionAnswers, "csio:47")
          .questionCd,
      expiry_date:
        PersPolicy?.OtherOrPriorPolicy?.ContractTerm?.ExpirationDt || "",
      prior_policy_number: PersPolicy?.OtherOrPriorPolicy?.PolicyNumber || "",
    },
    previous_accidents_and_insurance_claims:
      previous_accidents_and_insurance_claims,
    history_of_convictions,
    rating_information: {
      section_one: ratingInformationOne,
      section_two: ratingInformationTwo,
    },
    payment_reporter_of_broker: {
      amount_due: PersPolicy?.PaymentOption?.TotalDueAm,
      amount_of_each_installment:
        PersPolicy?.PaymentOption?.InstallmentInfo?.[0]?.InstallmentAmt?.Amt ||
        "",
      amount_paid_with_application:
        PersPolicy?.PaymentOption?.CollectedByAgentAmt || "",
      estimated_policy_premium: PersPolicy?.CurrentTermAmt?.Amt || "",
      has_an_inspection_been_done: "",
      have_you_bound_the_risk: haveYouBoundRisk ? haveYouBoundRisk.YesNoCd : "",
      how_long_you_have_known_the_applicant:
        PersPolicy?.PersApplicationInfo?.LengthTimeKnownByAgentBroker,
      how_long_you_have_known_the_principal_driver:
        PersPolicy?.PersApplicationInfo?.PrincipalDriverKnownByAgentBroker,
      installment_due_date:
        PersPolicy?.PaymentOption?.InstallmentInfo?.[0]?.InstallmentDueDt,
      interest_amount: PersPolicy?.InstallmentFeeAmt,
      is_this_business_new_to_you: newBusiness ? newBusiness.YesNoCd : "",
      no_of_remaining_installments:
        PersPolicy?.PaymentOption?.NumPayments || "",
      provincial_sales_tax:
        PersPolicy?.PaymentOption?.InstallmentInfo?.[0]?.TaxAmt,
      total_estimated_cost: PersPolicy?.TotalEstimatedAmt,
      type_of_motor_vehicle_liability_insurance_card:
        PersPolicy?.PersApplicationInfo?.VehLiabilityCardCd,
      type_of_payment_plan: typeOfPaymentLabel,
    },
    payment_schedule: describedPaymentschedule,
    incurance_coverage_applied_for: {
      columns: columns,
      rows: insuraceAppliedData,
    },
    remarks: null,
    status: "ACTIVE",
  };
};

const getDescibedAutoMobileQuestionAnswers = (
  questions: {
    QuestionCd: string;
    YesNoCd: string;
  }[],
  questionCd: string
) => {
  const question = questions.find((qa: any) => qa?.QuestionCd === questionCd);
  return {
    questionCd: question?.QuestionCd || null,
    yesNoCd: question?.YesNoCd || null,
  };
};
