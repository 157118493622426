import produce from "immer";
import { commonActions } from ".";
import { IStoreState } from "../initialStoreState";
import { defaultCommonState } from "./common.types";
import {
  CLEAR_DRIVERS_BY_POLICY_NUM,
  CLEAR_GENERAL_HISTORY_STATE,
  CLEAR_RECORD_COUNTS,
  CLEAR_RISK_LOCATIONS_BY_POLICY_NUM,
  CLEAR_VEHICLES_BY_POLICY_NUM,
  FETCH_CUSTOMER_ALL_DETAILS_FAILED,
  FETCH_CUSTOMER_ALL_DETAILS_PROGRESS,
  FETCH_CUSTOMER_ALL_DETAILS_SUCCESS,
  FETCH_DRIVERS_BY_POLICY_NUM_FAILED,
  FETCH_DRIVERS_BY_POLICY_NUM_PROGRESS,
  FETCH_DRIVERS_BY_POLICY_NUM_SUCCESS,
  FETCH_ENDPOINTS_LIST_FAILED,
  FETCH_ENDPOINTS_LIST_PROGRESS,
  FETCH_ENDPOINTS_LIST_SUCCESS,
  FETCH_GENERAL_HISTORY_LIST_FAILED,
  FETCH_GENERAL_HISTORY_LIST_PROGRESS,
  FETCH_GENERAL_HISTORY_LIST_SUCCESS,
  FETCH_MODULES_LIST_FAILED,
  FETCH_MODULES_LIST_PROGRESS,
  FETCH_MODULES_LIST_SUCCESS,
  FETCH_POLCIES_FAILED,
  FETCH_POLICIES_PROGRESS,
  FETCH_POLICIES_SUCCESS,
  FETCH_RECORD_COUNTS_FAILED,
  FETCH_RECORD_COUNTS_PROGRESS,
  FETCH_RECORD_COUNTS_SUCCESS,
  FETCH_RISK_LOCATIONS_BY_POLICY_NUM_FAILED,
  FETCH_RISK_LOCATIONS_BY_POLICY_NUM_PROGRESS,
  FETCH_RISK_LOCATIONS_BY_POLICY_NUM_SUCCESS,
  FETCH_VEHICLES_BY_POLICY_NUM_FAILED,
  FETCH_VEHICLES_BY_POLICY_NUM_PROGRESS,
  FETCH_VEHICLES_BY_POLICY_NUM_SUCCESS,
} from "./commonActions";
import { LoadState } from "../../constants/enums";

export const commonReducer = (
  state: IStoreState["common"] = defaultCommonState,
  action: commonActions
) => {
  switch (action.type) {
    case FETCH_POLICIES_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policies.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_POLICIES_SUCCESS: {
      const { policies } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policies.loadState = LoadState.Loaded;
        draftState.policies.list = policies;
      });
      return newState;
    }
    case FETCH_POLCIES_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policies.loadState = LoadState.Failed;
        draftState.policies.list = [];
      });
      return newState;
    }

    case FETCH_GENERAL_HISTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.generalHistory.loading = LoadState.InProgress;
        draftState.generalHistory.list = [];
      });
      return newState;
    }
    case FETCH_GENERAL_HISTORY_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.generalHistory.loading = LoadState.Loaded;
        draftState.generalHistory.list = data;
      });
      return newState;
    }
    case FETCH_GENERAL_HISTORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.generalHistory.loading = LoadState.Failed;
        draftState.generalHistory.list = [];
      });
      return newState;
    }
    case CLEAR_GENERAL_HISTORY_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.generalHistory = defaultCommonState["generalHistory"];
      });
      return newState;
    }

    case FETCH_RECORD_COUNTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.generalHistory.loading = LoadState.InProgress;
        draftState.generalHistory.list = [];
      });
      return newState;
    }
    case FETCH_RECORD_COUNTS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.recordCounts.loading = LoadState.Loaded;
        draftState.recordCounts.list = data;
      });
      return newState;
    }
    case FETCH_RECORD_COUNTS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.recordCounts.loading = LoadState.Failed;
        draftState.recordCounts.list = [];
      });
      return newState;
    }
    case CLEAR_RECORD_COUNTS: {
      const newState = produce(state, (draftState) => {
        draftState.recordCounts = defaultCommonState["recordCounts"];
      });
      return newState;
    }

    case FETCH_VEHICLES_BY_POLICY_NUM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.vehiclesByPolicyNumber.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_VEHICLES_BY_POLICY_NUM_SUCCESS: {
      const { data, policyNumber } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.vehiclesByPolicyNumber.loadState = LoadState.Loaded;
        draftState.vehiclesByPolicyNumber.policyId = policyNumber;
        draftState.vehiclesByPolicyNumber.list = data;
      });
      return newState;
    }
    case FETCH_VEHICLES_BY_POLICY_NUM_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.vehiclesByPolicyNumber.loadState = LoadState.Failed;
        draftState.vehiclesByPolicyNumber.list = [];
      });
      return newState;
    }
    case CLEAR_VEHICLES_BY_POLICY_NUM: {
      const newState = produce(state, (draftState) => {
        draftState.vehiclesByPolicyNumber =
          defaultCommonState["vehiclesByPolicyNumber"];
      });
      return newState;
    }

    case FETCH_DRIVERS_BY_POLICY_NUM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driversByPolicy.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DRIVERS_BY_POLICY_NUM_SUCCESS: {
      const { data, policyNumber } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.driversByPolicy.loadState = LoadState.Loaded;
        draftState.driversByPolicy.policyId = policyNumber;
        draftState.driversByPolicy.list = data;
      });
      return newState;
    }
    case FETCH_DRIVERS_BY_POLICY_NUM_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.driversByPolicy.loadState = LoadState.Failed;
        draftState.driversByPolicy.list = [];
      });
      return newState;
    }
    case CLEAR_DRIVERS_BY_POLICY_NUM: {
      const newState = produce(state, (draftState) => {
        draftState.driversByPolicy = defaultCommonState["driversByPolicy"];
      });
      return newState;
    }

    case FETCH_RISK_LOCATIONS_BY_POLICY_NUM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.driversByPolicy.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_RISK_LOCATIONS_BY_POLICY_NUM_SUCCESS: {
      const { data, policyNumber } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.riskLocationByPolicy.loadState = LoadState.Loaded;
        draftState.riskLocationByPolicy.policyId = policyNumber;
        draftState.riskLocationByPolicy.list = data;
      });
      return newState;
    }
    case FETCH_RISK_LOCATIONS_BY_POLICY_NUM_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.riskLocationByPolicy.loadState = LoadState.Failed;
        draftState.riskLocationByPolicy.list = [];
      });
      return newState;
    }
    case CLEAR_RISK_LOCATIONS_BY_POLICY_NUM: {
      const newState = produce(state, (draftState) => {
        draftState.riskLocationByPolicy =
          defaultCommonState["riskLocationByPolicy"];
      });
      return newState;
    }

    case FETCH_CUSTOMER_ALL_DETAILS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerDetail.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_ALL_DETAILS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerDetail.loading = LoadState.Loaded;
        draftState.customerDetail.data = data;
      });
      return newState;
    }
    case FETCH_CUSTOMER_ALL_DETAILS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.customerDetail.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_MODULES_LIST_PROGRESS: {
      const {isView} = action.payload;
      const newState = produce(state, (draftState) => {
        if(isView){
          draftState.modules.tableViews = {
            data: [], 
            loading: LoadState.InProgress,
          }
        }
        else {
          draftState.modules.tableNames = {
            data: [], 
            loading: LoadState.InProgress,
          }
        }
     
      });
      return newState;
    }
    case FETCH_MODULES_LIST_SUCCESS: {
      const {isView, data} = action.payload;
      const newState = produce(state, (draftState) => {
        if(isView){
          draftState.modules.tableViews = {
            data: data,
            loading: LoadState.Loaded,
          }
        }
        else {
          draftState.modules.tableNames = {
            data: data,
            loading: LoadState.Loaded,
          }
        }
     
      });
      return newState;
    }
    case FETCH_MODULES_LIST_FAILED: {
      const {isView} = action.payload;
      const newState = produce(state, (draftState) => {
        if(isView){
          draftState.modules.tableViews = {
            data: [], 
            loading: LoadState.Failed,
          }
        }
        else {
          draftState.modules.tableNames = {
            data: [], 
            loading: LoadState.Failed,
          }
        }
     
      });
      return newState;
    }

    case FETCH_ENDPOINTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.endPointsByModule.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ENDPOINTS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.endPointsByModule.loading = LoadState.Loaded;
        draftState.endPointsByModule.data = data;
      });
      return newState;
    }
    case FETCH_ENDPOINTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.endPointsByModule.loading = LoadState.Failed;
        draftState.endPointsByModule.data = {};
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
