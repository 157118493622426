import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import "./App.css";
import { RTL } from "./layout/fullLayout/customizer/RTL";
import { ThemeSettings } from "./layout/fullLayout/customizer/ThemeSettings";
import { IStoreState } from "./redux/initialStoreState";
import { Router } from "./router/router";
import { useDispatchWrapper } from "./hooks";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TableProvider } from "./components/Table/contexts/TableProvider";
import { DataTableV2Provider } from "./components/TableV2/context/DataTableV2Provider";

function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const dispatch = useDispatchWrapper();
  const customizer = useSelector(
    (state: IStoreState) => state.customizerReducer
  );


  return (
    <ThemeProvider theme={theme}>
      <TableProvider tableConfig={{
        stickyHeader: true,
      }}>
        <DataTableV2Provider tableConfig={{stickyHeader: true}}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <RTL direction={customizer.activeDir as "ltr"}>
          <CssBaseline />
          {routing}
        </RTL>
      </LocalizationProvider>
      </DataTableV2Provider>
      </TableProvider>
    </ThemeProvider>
  );
}

export default App;
