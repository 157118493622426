import React from "react";
import { StandadCard } from "../../../../components/Cards";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../components/formsComponents";
import {
  Add,
  AddCircleOutlineOutlined,
  SendOutlined,
} from "@mui/icons-material";
import { ISendReceiptProps } from "./SendReceipt.types";
import {
  IDataTableProps,
  RenderType,
} from "../../../../components/Table/interfaces/IDataTableProps";
import { StandardTableActions } from "../../../../components/StandardTableActions/StandardTableActions";
import { StatusRenderer } from "../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { LoadState } from "../../../../constants/enums";
import { DataTable } from "../../../../components/Table/DataTable";
import { IG4SignDocumentRecipient } from "../../../../redux/g4SignDocuments/g4SignDocuments.types";
import { AddRecipientDialog } from "./AddRecipientDialog";
import { useDispatchWrapper } from "../../../../hooks";
import {
  fetchG4SignRecipientListAsync,
  resendEmailToRecipient,
  upsertG4Recipient,
} from "../../../../redux/g4SignDocuments/g4SignDocumentsAction";
import { RefreshPlugin } from "../../../../components/Table/plugins";
import { SignOrderSelect } from "./SignOrderSelect";
import { ConfirmDialog } from "../../../../components/Dialogs/ConfirmDialog";
import { useRoles } from "../../../../security/RolesProvider/RolesProvider";

const INITIAL_STATE: IG4SignDocumentRecipient = {
  document_code: "",
  email: "",
  expired: "",
  signer_name: "",
  send_status: "",
  status: "ACTIVE",
};

export const SendReceipt: React.FC<ISendReceiptProps> = (props) => {
  const { documentCode } = props;
  const { recipientList, recipientLoading, document } = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents
  );
  const [open, setOpen] = React.useState<IG4SignDocumentRecipient | null>(null);
  const [openDelete, setOpenDelete] =
    React.useState<IG4SignDocumentRecipient | null>(null);
  const dispatch = useDispatchWrapper();
  const { firstName, lastName } = useRoles();

  const fetchList = () => {
    dispatch(fetchG4SignRecipientListAsync(documentCode));
  };

  const handleComplete = () => {
    setOpen(null);
    fetchList();
  };

  const handleDeleteConfirm = () => {
    setOpenDelete(null);
    if (openDelete) {
      dispatch(
        upsertG4Recipient({...openDelete, status: "INACTIVE"}, (isSuccess) => {
          if(isSuccess){
            fetchList();
          }
        })
      );
    }
  };

  const tableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: recipientLoading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "refresh",
            onRender: () => {
              return <RefreshPlugin onClick={fetchList} />;
            },
          },
        ],
      },
      rightItems: {
        customPlugins: [
          {
            key: "Add",
            onRender: () => {
              return (
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => {
                    setOpen({ ...INITIAL_STATE, document_code: documentCode });
                  }}
                >
                  Add Signer
                </Button>
              );
            },
          },
        ],
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,

        onRowCellRender: (value, row: IG4SignDocumentRecipient) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                setOpen(row);
              }}
              
              onDeleteClick={row.send_status === "NOT_SENT" ?() => {
                setOpenDelete(row);
              }: undefined}
            />
          );
        },
      },
      {
        key: "document_code",
        headerName: "Document Code",
        fieldName: "document_code",
        enableSorting: true,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "signer_name",
        headerName: "Signer Name",
        fieldName: "signer_name",
        enableSorting: true,
        renderType: RenderType.TEXT,
      },
      {
        key: "sign_order",
        headerName: "Sign Order",
        fieldName: "sign_order",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <SignOrderSelect
              recipient={row}
              recipientLength={recipientList.length}
              onComplete={fetchList}
            />
          );
        },
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "send_status",
        headerName: "Sent Status",
        fieldName: "send_status",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "read_status",
        headerName: "Read Status",
        fieldName: "read_status",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "signed_status",
        headerName: "Signed Status",
        fieldName: "signed_status",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "signed_at",
        headerName: "Signed At",
        fieldName: "signed_at",
        enableSorting: true,
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 25,
      },
      // {
      //   key: "expired",
      //   headerName: "Email",
      //   fieldName: "email",
      //   enableSorting: true,
      //   renderType: RenderType.TEXT_DARK,
      // },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },

      {
        key: "insert_ts",
        headerName: "Date Created",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 25,
      },
      {
        key: "resent",
        headerName: "Send Email",
        fieldName: "",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        isLastColumnSticky: true,
        onRowCellRender:(value, row: any)=> {
            return <Button variant="contained" disabled={document.status !== "PENDING"} onClick={()=>{

              dispatch(resendEmailToRecipient(row.recipient_code, `Signature Requested by ${firstName} ${lastName ? lastName:''}`))
            }} >Send Email</Button>
        },
      },
    ],

    items: recipientList,
  };

  React.useEffect(() => {
    fetchList();
  }, []);

  return (
    <Box mt={6}>
      <Grid container>
        <Grid item xs={12}>
          <DataTable {...tableProps} />
        </Grid>
        <Grid item xs={12} md={2} mt={3}>
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => props.changeStep(0)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={() => props.changeStep(2)}
            >
              Next
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/* <Grid container spacing={2} mt={4}>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Title</CustomFormLabel>
          <CustomTextField fullWidth value={""} placeholder="Document Title" />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <CustomFormLabel>Message for signers</CustomFormLabel>
          <CustomTextField
            fullWidth
            value={""}
            placeholder="Message (optional)"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        
      </Grid> */}
      {open && (
        <AddRecipientDialog
          open={true}
          data={open}
          onClose={() => setOpen(null)}
          onComplete={handleComplete}
        />
      )}
      {openDelete && (
        <ConfirmDialog
          open={true}
          content={
            <Stack>
              <Typography variant="body1" fontSize={'1.1rem'} fontWeight={600} textAlign={"center"}>
                Are you sure you want to delete the Signer!
              </Typography>
            </Stack>
          }
          onConfrim={handleDeleteConfirm}
          onClose={() => setOpenDelete(null)}
        />
      )}
    </Box>
  );
};
