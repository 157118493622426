import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IBillPaymentPayableState {
  data: IBillPaymentPayable;
  loading: LoadState;
  list: IBillPaymentPayable[];
  listLoading: LoadState;
  error: any;
}

export interface IBillPaymentDetails {
  key: string;
  date: string;
  bill_number: string;
  bill_amount: string;
  amount_due: string;
  payment: string;
  fromVendor: boolean;
}

export interface IBillPaymentPayable {
  create_payment_code: string | null;
  customer_id: string | null;
  customer_policy_id: string | null;

  payment_id: string | null;
  vendor: string | null;
  payment_made: string;
  bank_charges: string | null;
  payment_method: string | null;
  paid_through_account: string | null;
  reference_id: string | null;
  bill_payment_details: IBillPaymentDetails[];
  total_amount: string;
  amount_paid: string;
  amount_used_for_payments: string;
  amount_refunded: string;
  amount_in_excess: string;
  notes: string;
  status: string;
}

export const initialBillPaymentPayableState: IBillPaymentPayableState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    create_payment_code: null,
    customer_id: null,
    customer_policy_id: null,
    payment_id: null,
    vendor: null,
    payment_made: "",
    bank_charges: null,
    payment_method: null,
    paid_through_account: null,
    reference_id: null,
    bill_payment_details: [
      {
        key: getUniqueId(),
        date: "",
        amount_due: "",
        bill_amount: "",
        bill_number: "",
        payment: "",
        fromVendor: false,
      },
    ],
    total_amount:  "",
    amount_paid:  "",
    amount_used_for_payments:  "",
    amount_refunded:  "",
    amount_in_excess:  "",
    notes: "",
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
