import Avatar from "@mui/material/Avatar";
import React from "react";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin, SearchPlugin } from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import moment from "moment";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { Button, MenuItem } from "@mui/material";
import { CustomSelect } from "../../../components/formsComponents";
import {
  capitalizeWords,
  createTabsWithRecordcounts,
  renderDropdownValue,
} from "../../../helpers";
import { BeforeCreateTypes } from "./BeforeCreate.types";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { fetchTasksListAsync } from "../../../redux/tasks/tasksActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { ITask } from "../../../redux/tasks/task.types";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { ViewTaskDetails } from "./ViewTaskDetails/ViewTaskDetails";
import { LeadOppStatus } from "../../../components/LeadOppStatus/LeadOppStatus";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { TASK_STATUS, task_status } from "../../../constants/constants";
import { TaskAssignedTo } from "./TaskAssignedTo";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import { api } from "../../../api/api";
import { ViewOldBusinessTracker } from "./ViewOldBusinessTracker/ViewOldBusinessTracker";
import { IViewOldBusinessTrackerState } from "./ViewOldBusinessTracker/ViewOldBusinessTracker.types";
import { IOldBusinessTrackerState } from "./OldBusinessTrackerDialog/OldBusinessTrackerDialog.types";
import { OldBusinessTrackerDialog } from "./OldBusinessTrackerDialog/OldBusinessTrackerDialog";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";

// export interface IOldBussinesTracker {
//   customer_policy_id: number;
//   incremental_number: string | null;
//   customer_code: string;
//   customer_name: string | null;
//   driver_number: string | null;
//   driver_name: string | null;
//   license_number: string | null;
//   business_reason: string | null;
//   date_and_time: string | null;
//   days_left: string | null;
//   transaction_type: string | null;
//   report_requested: string | null;
//   years_at_this_address: string | null;
//   current_address_type: string | null;
//   current_address: string | null;
//   current_address_unit_or_suite: string | null;
//   current_address_city: string | null;
//   current_address_state_or_province: string | null;
//   current_address_postal_code: string | null;
//   current_address_country: string | null;
//   new_address_type: string | null;
//   new_address: string | null;
//   new_address_unit_or_suite: string | null;
//   new_address_city: string | null;
//   new_address_state_or_province: string | null;
//   new_address_postal_code: string | null;
//   new_address_country: string | null;
//   request_status: string | null;
//   insurer_code: string | null;
//   intact_branch: string | null;
//   number_of_disclosed_convictions: string | null;
//   policy_number: string | null;
//   policy_status_cancelled: string | null;
//   requested_by: string | null;
//   requested_by_code: string | null;
//   assigned_to: string | null;
//   branch: string | null;
//   producer: string | null;
//   csr: string | null;
//   notes: string | null;
//   file_upload: string | null;
//   file_upload_two: string | null;
//   file_type: string | null;
//   file_type_two: string | null;
//   date_written: string | null;
//   date_destroyed: string | null;
//   date_voided: string | null;
//   date_cancelled: string | null;
//   producer_one_code: string | null;
//   csr_code: string | null;
//   insurer_code_written: string | null;
//   status: string;
// }

export const OldBusinessTracker: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "insurance-reports",
    },
  ];
  const [state, setState] = React.useState({
    totalRecord: 0,
    loading: LoadState.NotLoaded,
    list: [],
  });

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeCreateTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [tableTabs, setTableTabs] = React.useState<ITableTab[]>([]);
  const [status, setStatus] = React.useState<
    BeforeCreateTypes["status"] | "-1"
  >("-1");

  const [openView, setOpenView] =
    React.useState<IViewOldBusinessTrackerState | null>(null);
  const [editOldBusinessTracker, setEditOldBusinessTracker] =
    React.useState<IOldBusinessTrackerState | null>(null);

  const [searchType, setSearchType] = React.useState<string[]>([
    "customer_name",
  ]);
  const [search, setSearch] = React.useState<string>("");

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });

  const dispatch = useDispatchWrapper();

  const navigate = useNavigate();
  const fetchList = async () => {
    setState({ ...state, loading: LoadState.InProgress });
    try {
      let finalUrl = `/other/get-old-business-tracker?pageNo=${tablePagination.pageNumber}&itemPerPage=${tablePagination.rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchType.length > 0 && search) {
        finalUrl = finalUrl + `&column=${[searchType]}&value=${search}`;
      }
      if (status !== "-1") {
        finalUrl = finalUrl + `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IViewOldBusinessTrackerState[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      setState({
        ...state,
        list: data as any,
        totalRecord: totalRecords,
        loading: LoadState.Loaded,
      });
    } catch (err: any) {
      console.log("err");
      setState({ ...state, loading: LoadState.Failed });
    }
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, status, search, searchType]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const opportunitesTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: state.totalRecord,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: state.loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value={"-1"}>{"All Status"}</MenuItem>;
                {task_status.map((task) => {
                  return <MenuItem value={task}>{task}</MenuItem>;
                })}
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                moduleId={MODULE_IDS.ALL_TASK}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {
          // searchField: {
          //   searchKeys: ["opportunity_id", "customer_id", "lead_id", "status"],
          //   items: list,
          // },
        },
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Customer Name", value: "customer_name" },
                    { label: "Policy Number", value: "policy_number" },
                    { label: "Task Name", value: "task_name" },
                    { label: "Task Code", value: "task_code" },
                    { label: "Task Type", value: "task_type" },
                    { label: "Task Sub-Type", value: "task_sub_type" },
                    { label: "Task Priority", value: "task_priority" },
                    { label: "Assigned To", value: "first_name" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    columns: [
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IViewOldBusinessTrackerState) => {
          return <LeadOppStatus status={row.status || ""} />;
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "task_type",
        headerName: "Transaction",
        fieldName: "task_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "task_sub_type",
        headerName: "Sub-Type",
        fieldName: "task_sub_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },

      {
        key: "task_created_date",
        headerName: "Date Created",
        fieldName: "task_created_date",
        renderType: RenderType.DATE,
      },
      {
        key: "task_due_date",
        headerName: "Due Date",
        fieldName: "task_due_date",
        renderType: RenderType.DATE,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: RenderType.DATE_TIME_DARK_COLOR,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onViewClick={() =>
                setOpenView(row as IViewOldBusinessTrackerState)
              }
              onEditClick={() => {
                console.log(row, "rowowowow");
                setEditOldBusinessTracker(row as IViewOldBusinessTrackerState);
              }}
            />
          );
        },
      },
    ],
    items: state.list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: BeforeCreateTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
    tableTabProps: {
      selectedTab: status,
      tabs: tableTabs,
      onTabChange:(newSelectedTab) =>{
        setStatus(newSelectedTab);
      },
    },
  };

  console.log("state.list", state.list);

  const handleClick = () => {
    navigate("/tasks");
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(TASK_STATUS, recordsCounts);
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "old_business_tracker",
        "task_status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  return (
    <PageContainer
      title="Old Business Tracker"
      description="this is before create task"
    >
      {/* <IconicMultiColumnCard
        loading={recordCountsLoading !== LoadState.Loaded}
        columns={recordsCounts.map((item) => {
          return {
            heading: capitalizeWords(item.name),
            value: item.cnt,
            colSize: 2,
          };
        })}
      /> */}

      <StandadCard heading="Old Business Tracker">
        <DataTable {...opportunitesTableProps} />
      </StandadCard>

      {openView && (
        <ViewOldBusinessTracker
          open={true}
          onClose={() => setOpenView(null)}
          data={openView}
        />
      )}

      {editOldBusinessTracker && (
        <OldBusinessTrackerDialog
          open={true}
          onClose={() => setEditOldBusinessTracker(null)}
          data={editOldBusinessTracker}
          refreshList={fetchList}
        />
      )}
    </PageContainer>
  );
};
