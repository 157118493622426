/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { RightPanel } from "../../RightPanel";
import { useDispatchWrapper } from "../../../hooks";
import { useFormik } from "formik";
import { IEmailComposeDialog } from "./EmailCompose.types";
import { sendEmail } from "../../../redux/common/commonActions";
import { Button, Grid, SelectChangeEvent } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../formsComponents";
import { QuillEditor } from "../../QuillEditor/QuillEditor";
import { IMail } from "../../../redux/common/common.types";
import { api } from "../../../api/api";
import { fetchTemplateListAllTempsAsync } from "../../../redux/templates/templateActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { ICreateTemplate } from "../../../redux/templates/templates.types";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";
import { FileUpload } from "../../FileUpload/FileUpload";
import { getUniqueId } from "../../../helpers";
import { IFileUpload } from "../../FileUpload/FileUpload.type";
import { SHARED_EMAILS } from "../../../constants/constants";
import { useRoles } from "../../../security/RolesProvider/RolesProvider";
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";

const INITIAL_MAIL_PAYLOAD: IMail = {
  from_email: null,
  from_name: null,
  emails: [],
  subject: "",
  body: "",
  templateName: null,
  objectVariables: {},
  bcc: [],
  cc: [],
  reply_to: {
    email: "",
    name: "",
  },
};

export const EmailComposeDialog: React.FC<IEmailComposeDialog> = (props) => {
  const { toEmail, open, onClose, id, attachements = [],subject } = props;
  const { sharedEmails } = useRoles();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const [selectedTemplate, setSelectedTemplate] = React.useState("");
  const templates = useSelector(
    (storeState: IStoreState) => storeState.templates.list
  );
  const { user } = useAuth();
  const [attachmentsList, setAttachmentsList] = React.useState<IFileUpload[]>(
    attachements.map((x) => ({ file: null, key: getUniqueId(), path: x }))
  );
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      ...INITIAL_MAIL_PAYLOAD,
      emails: toEmail ? ["ravindersingh1526@gmail.com"] : [],
      subject: subject || "",
      reply_to: {
        email: user.email,
        name: user.name,
      },
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.subject) {
        errors.subject = "Subject is requried!";
      } else if (!values.body) {
        errors.body = "Body is requried!";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        sendEmail(values, attachmentsList, (isSucess) => {
          if (isSucess) {
            onClose();
          }
          setLoading(false);
        })
      );
    },
  });

  const handleEmailReplyTo = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({
      ...values,
      reply_to: {
        email: e.target.value,
        name: "",
      },
    });
  };

  const handleFromChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string
    const selectedOption = SHARED_EMAILS.find(option => option.value === value);

    if (selectedOption) {
      setValues({
        ...values,
        from_email: value,
        from_name: selectedOption.label
      })
    }

  }

  const emailLists = React.useMemo(() => {
    return SHARED_EMAILS.filter((x) => sharedEmails.includes(x.value));
  }, [sharedEmails]);

  React.useEffect(() => {
    dispatch(fetchTemplateListAllTempsAsync());
  }, []);

  React.useEffect(() => {
    const fetchTemplate = async (temp: ICreateTemplate) => {
      try {
        const res = await api.get(
          `/templates/get-templates?templates_id=${temp.templates_id}`
        );
        const list: ICreateTemplate[] = res.data.data;
        if (list.length > 0) {
          setFieldValue("body", list[0].body);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedTemplate) {
      const template = templates.find(
        (x) => x.template_name === selectedTemplate
      );
      if (template) {
        fetchTemplate(template);
      }
    }
  }, [selectedTemplate]);

  return (
    <RightPanel
      open={open}
      heading="Send Message as Email"
      subHeading="Connecting Through Email: Delivering Messages to Specified Email"
      onClose={onClose}
      isWrappedWithForm
      onFormSubmit={handleSubmit}
      actionButtons={() => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                fullWidth
              >
                Send
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" fullWidth onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        );
      }}
    >
      <Grid container>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>From</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            name="from_email"
            value={values.from_email}
            onChange={handleFromChange}
            placeholder="Select one"
            displayEmpty
            options={emailLists}
          ></ControlledCustomSelect>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>To</CustomFormLabel>

          <CustomTextField
            disabled={toEmail ? true : false}
            fullWidth
            name="emails[0]"
            value={values.emails[0]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>Reply</CustomFormLabel>
          <CustomTextField
            name="reply_to.email"
            fullWidth
            value={values.reply_to ? values.reply_to.email : ""}
            onChange={handleEmailReplyTo}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>Subject</CustomFormLabel>
          <CustomTextField
            name="subject"
            fullWidth
            value={values.subject}
            onChange={handleChange}
            error={errors.subject ? true : false}
            helperText={errors.subject}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>Template</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={selectedTemplate}
            name="sub_mobules"
            onChange={(e) => setSelectedTemplate(e.target.value as string)}
            placeholder="Choose Template"
            displayEmpty
            options={templates.map((item) => {
              return { label: item.template_name, value: item.template_name };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomFormLabel>Message</CustomFormLabel>

          <QuillEditor
            value={values.body}
            onChange={(html) => setFieldValue("body", html)}
          />
          {errors.body && <ErrorMessage value={errors.body} />}
        </Grid>
        {attachements.length > 0 && (
          <Grid item xs={12} md={12}>
            <CustomFormLabel>Attachments</CustomFormLabel>
            <FileUpload
              multiple
              value={attachmentsList}
              disabled
              deleteDisabled
            />
          </Grid>
        )}
      </Grid>
    </RightPanel>
  );
};
