import { action } from "typesafe-actions";

import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ILegal } from "./legals.types";
import { uploadFile } from "../../helpers";
import { makeApiCall } from "../../helpers/postRequest";
import { endPoints } from "../../constants/endPoints";

export const FETCH_LEGALS_LIST_PROGRESS = "FETCH_LEGALS_LIST_PROGRESS";
export const FETCH_LEGALS_LIST_SUCCESS = "FETCH_LEGALS_LIST_SUCCESS";
export const FETCH_LEGALS_LIST_FAILED = "FETCH_LEGALS_LIST_FAILED";

export const fetchLegalsListProgress = () => action(FETCH_LEGALS_LIST_PROGRESS);
export const fetchLegalsListSuccess = (list: ILegal[], totalRecords: number) =>
  action(FETCH_LEGALS_LIST_SUCCESS, { list, totalRecords });
export const fetchLegalsListFailed = () => action(FETCH_LEGALS_LIST_FAILED);

export const fetchLegalsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLegalsListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/other/get-legal-plus?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/other/get-legal-plus?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await api.get(finalUrl);
      const data: ILegal[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      console.log("from action file", data, totalRecords);
      dispatch(fetchLegalsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchLegalsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_LEGAL_PROGRESS = "FETCH_LEGAL_PROGRESS";
export const FETCH_LEGAL_SUCCESS = "FETCH_LEGAL_SUCCESS";
export const FETCH_LEGAL_FAILED = "FETCH_LEGAL_FAILED";

export const fetchLegalProgress = () => action(FETCH_LEGAL_PROGRESS);
export const fetchLegalSuccess = (legal: ILegal) =>
  action(FETCH_LEGAL_SUCCESS, { legal });
export const fetchLegalFailed = (errorMessage: string) =>
  action(FETCH_LEGAL_FAILED, { errorMessage });

export const fetchLegalAsync =
  (table_name?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLegalProgress());

      const res = await api.get(`/other/get-legal-plus`);
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchLegalSuccess(data[0]));
      } else {
        dispatch(
          fetchLegalFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchLegalFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertLegalAysnc =
  (
    legal: ILegal,
    initialData: ILegal,
    isUpdate: boolean,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        legal_plus_code: legal.legal_plus_code,
      };
      const path = await uploadFile(
        file,
        "LEGAL",
        legal.attachment || "",
        asPayload
      );
      await makeApiCall({
        url: endPoints.legalPlus.upsertLegal,
        method: "POST",
        automation: {
          isUpdate: true,
          primaryFieldName :"legal_plus_code"
        }
      },initialData, {...legal,  attachment: path,});
    
      dispatch(
        showMessage({
          type: "success",
          message: "Legal is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_LEGAL_STATE = "CLEAR_LEGAL_STATE";
export const CLEAR_LEGAL = "CLEAR_LEGAL";

export const clearLegalsState = () => action(CLEAR_LEGAL_STATE);
export const clearLegal = () => action(CLEAR_LEGAL);
