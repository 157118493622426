import { action } from "typesafe-actions";
import { ICSIO, ICSIORaw } from "./csio.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { ERROR_MESSAGES } from "../../constants/enums";
export const FETCH_CSIO_LIST_PROGRESS = "FETCH_CSIO_LIST_PROGRESS";
export const FETCH_CSIO_LIST_SUCCESS = "FETCH_CSIO_LIST_SUCCESS";
export const FETCH_CSIO_LIST_FAILED = "FETCH_CSIO_LIST_FAILED";

export const fetchCSIOListProgress = () => action(FETCH_CSIO_LIST_PROGRESS);
export const fetchCSIOListSuccess = (list: ICSIORaw[], totalRecords: number) =>
  action(FETCH_CSIO_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchCSIOListFailed = () => action(FETCH_CSIO_LIST_FAILED);

export const fetchCSIOListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    searchValue: string,
    searchType: string[],
    date: IDate,
    map?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOListProgress());

      let finalUrl = `/csio/get-csio-retrieve-messages?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/csio/get-csio-retrieve-messages?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }

      if (map && map !== "-1") {
        finalUrl += `&column=is_map&value=${map}`;
      }

      const res = await api.get(finalUrl);
      const data: ICSIORaw[] = res.data.data;
      console.log("record", res.data.totalRecords);
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCSIOListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCSIOListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchFromCSIOListAsync =
  (
    fromDate: string,
    toDate: string,
    onCallback?: (code: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const finalUrl = `/csio/load-csio-list`;

      const res = await api.post(finalUrl, {
        "from_date": fromDate,
        "to_date": toDate
      });
      const code = res.data.data;
      if (onCallback) {
        onCallback(code);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const addInitializeMappingAsync =
  (
    only_for_unmapped: string,
    fromUtcDate: string,
    toUtcDate: string,
    onCallback: (isSuccess: boolean, code?: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/csio/csio-manual-data-mapping", {
        from_date: fromUtcDate,
        only_for_unmapped:only_for_unmapped,
        to_date: toUtcDate,
   
      });
      onCallback(true,res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Mapping done successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_CSIO_PROGRESS = "FETCH_CSIO_PROGRESS";
export const FETCH_CSIO_SUCCESS = "FETCH_CSIO_SUCCESS";
export const FETCH_CSIO_FAILED = "FETCH_CSIO_FAILED";

export const fetchCSIOProgress = () => action(FETCH_CSIO_PROGRESS);
export const fetchCSIOSuccess = (data: ICSIORaw) =>
  action(FETCH_CSIO_SUCCESS, {
    data,
  });
export const fetchCSIOFailed = (errorMessage: string) =>
  action(FETCH_CSIO_FAILED, { errorMessage });

export const fetchCSIOAsync =
  (messageGUid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOProgress());
      const res = await api.get(
        `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchCSIOSuccess({ ...data[0] }));
      } else {
        dispatch(fetchCSIOFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchCSIOFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_CSIO_STATE = "CLEAR_CSIO_STATE";
export const clearCSIOState = () => action(CLEAR_CSIO_STATE);

export const CLEAR_CSIO_RAW_DATA = "CLEAR_CSIO_RAW_DATA";
export const clearCSIORawData = () => action(CLEAR_CSIO_RAW_DATA);
