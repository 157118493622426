import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import {
  FETCH_HABITATIONAL_APPLICATION_LIST_FAILED,
  FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS,
  FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS,
  FETCH_HABITATIONAL_APPLICATION_FAILED,
  FETCH_HABITATIONAL_APPLICATION_PROGRESS,
  FETCH_HABITATIONAL_APPLICATION_SUCCESSS,
  CLEAR_HABITATIONAL_APPLICATION_STATE,

} from "./habitationalApplication.Actions";
import { HabitationalApplicationAction } from ".";
import { initialHabitationalApplicationState } from "./habitationalApplicationNew.types";

export const habitationalApplicationReducer = (
  state: IStoreState["habitational"]["applications"] = initialHabitationalApplicationState,
  action: HabitationalApplicationAction
) => {
  switch (action.type) {

    case FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_APPLICATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }


    case FETCH_HABITATIONAL_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.error = null;
        draftState.data = initialHabitationalApplicationState["data"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_APPLICATION_SUCCESSS: {
      const { data } = action.payload
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data
        // {
        //   ...dataStepOne,
        //   ...initialHabitationalApplicationState["data"]
        // }
      });
      return newState;
    }
    case FETCH_HABITATIONAL_APPLICATION_FAILED: {
      const { error } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.loading = LoadState.Failed;
        draftState.data =
          initialHabitationalApplicationState["data"];
          draftState.error = error || null;
      });
      return newState;
    }
    case CLEAR_HABITATIONAL_APPLICATION_STATE: {
      return initialHabitationalApplicationState;
    }
    // case CLEAR_HABITATIONAL_APPLICATION_STEP_TWO_STATE: {
    //   return initialHabitationalApplicationState["step2"];
    // }
    // case CLEAR_HABITATIONAL_APPLICATION_STEP_THREE_STATE: {
    //   return initialHabitationalApplicationState["step3"];
    // }
    default: {
      return state;
    }
  }
};
