import { action } from "typesafe-actions";
import { ILead } from "./leads.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { endPoints } from "../../constants/endPoints";
import { makeApiCall } from "../../helpers/postRequest";
import { initiateWorkFlow } from "../common/commonActions";

export const FETCH_LEADS_LIST_PROGRESS = "FETCH_LEADS_LIST_PROGRESS";
export const FETCH_LEADS_LIST_SUCCESS = "FETCH_LEADS_LIST_SUCCESS";
export const FETCH_LEADS_LIST_FAILED = "FETCH_LEADS_LIST_FAILED";

export const fetchLeadsListProgress = () => action(FETCH_LEADS_LIST_PROGRESS);
export const fetchLeadsListSuccess = (list: ILead[], totalRecords: number) =>
  action(FETCH_LEADS_LIST_SUCCESS, { list, totalRecords });
export const fetchLeadsListFailed = () => action(FETCH_LEADS_LIST_FAILED);

export const fetchLeadsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
    isConvertStatus: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLeadsListProgress());
      let url = "";
      let isConvertStatusUrl = "&status=" + isConvertStatus;
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/lead/get-leads?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/lead/get-leads?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await api.get(finalUrl);
      const data: ILead[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchLeadsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchLeadsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_LEAD_PROGRESS = "FETCH_LEAD_PROGRESS";
export const FETCH_LEAD_SUCCESS = "FETCH_LEAD_SUCCESS";
export const FETCH_LEAD_FAILED = "FETCH_LEAD_FAILED";

export const fetchLeadProgress = () => action(FETCH_LEAD_PROGRESS);
export const fetchLeadSuccess = (lead: ILead) =>
  action(FETCH_LEAD_SUCCESS, { lead });
export const fetchLeadFailed = (errorMessage: string) =>
  action(FETCH_LEAD_FAILED, { errorMessage });

export const fetchLeadAsync =
  (leadId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchLeadProgress());

      const res = await api.get(
        `${endPoints.leads.fetchleads}?value=${leadId}&column=${["lead_id"]}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchLeadSuccess(data[0]));
      } else {
        dispatch(
          fetchLeadFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchLeadFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertLeadAysnc =
  (
    lead: ILead,
    initialLead: ILead,
  
    isUpdate: boolean,
    isopportunity: boolean,
    onCallback: (isSuccess: boolean, isopportunity?: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const res = await makeApiCall(
        {
          url: endPoints.leads.upsertLead,
          method: "POST",
          automation: {
            primaryFieldName: "lead_id",
            isUpdate: isUpdate,
          },
        },
        initialLead,
        lead
      );

      let message = "Lead is saved successfully.";
      if (isopportunity) {
        await api.post(endPoints.opportunity.createOpportunity, {
          first_name: lead.first_name,
          last_name: lead.last_name,
          email: lead.email,
          phone: lead.phone,
          lead_id: lead.lead_id,
          lead_source: lead.lead_source,
          insurance_type: lead.insurance_type,
          insurance_sub_type: lead.insurance_sub_type,
          notes: lead.notes,
          comment: lead.comment,
          time_to_contact: lead.time_to_contact,
          assigned_to_id: lead.assigned_to_id,
          due_amount: null,
          amount: null,
          type: null,
          source: lead.lead_source,
          specify: lead.specify,
          probability: null,
          business_name: lead.business_name,
          next_step: null,
          description: null,
          file_upload: null,
          status: lead.status,
        });
        message = "Lead successfully converted to opportunity!";
      }

      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true, isopportunity);
      return res;
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_LEAD_STATE = "CLEAR_LEAD_STATE";
export const CLEAR_LEAD = "CLEAR_LEAD";

export const clearLeadsState = () => action(CLEAR_LEAD_STATE);
export const clearLead = () => action(CLEAR_LEAD);
