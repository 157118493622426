import { LoadState } from "../../constants/enums";

export interface ICSIOState {
  list: ICSIORaw[];
  loading: LoadState;
  totalRecords: number;
  rawData: ICSIORaw;
  rawDataLoading: LoadState;
  error: string | null;
}

export interface ICSIO {
  MessageGUID: string;
  FromEmailAddress: string;
  MessageType: string;
  MessageSubject: string;
  DateTime: string;
  CreatedDate: string;
  MessageSize: string;
}

export const initialCSIOState: ICSIOState = {
  list: [],
  loading: LoadState.NotLoaded,
  totalRecords: 0,
  rawData: {
    attachment: "",
    create_ts: "",
    created_by_id: "",
    from_email_address: "",
    is_map: 0,
    message_guid: "",
    message_subject: "",
    message_type: "",
    status: "ACTIVE",
    xml_json_message: "",
    xml_message: "",
   
  },
  rawDataLoading: LoadState.NotLoaded,
  error: null,
};

export interface ICSIORaw {
  message_guid: string;
  from_email_address: string;
  message_type: string;
  message_subject: string;
  attachment: string;
  is_map: number;
  xml_message: string;
  xml_json_message: any;
  status: string;
  created_by_id: string;
  create_ts: string;
}
