
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { transformCSIOToAutomobile } from "./transformCSIOToAutomobile";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ICSIORaw } from "../csio/csio.types";
import { IAutomobileApplication } from "../automobileApplication/automobileApplication.types";


export const FETCH_AUTOMOBILE_CSIO_LIST_PROGRESS = "FETCH_AUTOMOBILE_CSIO_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_CSIO_LIST_SUCCESS = "FETCH_AUTOMOBILE_CSIO_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_CSIO_LIST_FAILED = "FETCH_AUTOMOBILE_CSIO_LIST_FAILED";

export const fetchCSIOAutomobileListProgress = () => action(FETCH_AUTOMOBILE_CSIO_LIST_PROGRESS);
export const fetchCSIOAutomobileListSuccess = (list: ICSIORaw[], totalRecords: number) =>
  action(FETCH_AUTOMOBILE_CSIO_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchCSIOAutomobileListFailed = () => action(FETCH_AUTOMOBILE_CSIO_LIST_FAILED);

export const fetchCSIOAutomobileListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    searchValue: string,
    searchType: string[],
    date: IDate,
    map: string,
    customerpolicyId: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOAutomobileListProgress());
      let finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=PersAutoPolicyAddRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=PersAutoPolicyAddRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      if (map && map !== "-1") {
        finalUrl += `&is_map=${map}&column=xml_message&value=PersAutoPolicyAddRs`
      }
      if( customerpolicyId){
        finalUrl += `&customer_policy_id=${customerpolicyId}`
      }

      const res = await api.get(finalUrl);
      const data: ICSIORaw[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCSIOAutomobileListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCSIOAutomobileListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const fetchCSIOPolicyChangeListProgress = () => action(FETCH_AUTOMOBILE_CSIO_LIST_PROGRESS);
export const fetchCSIOPolicyChangeListSuccess = (list: ICSIORaw[], totalRecords: number) =>
  action(FETCH_AUTOMOBILE_CSIO_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchCSIOPolicyChangeListFailed = () => action(FETCH_AUTOMOBILE_CSIO_LIST_FAILED);

export const fetchCSIOPolicyChangeListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    searchValue: string,
    searchType: string[],
    date: IDate,
    map: string,
    customerpolicyId: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOPolicyChangeListProgress());
      let finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=PersAutoPolicyModRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=PersAutoPolicyModRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      if (map && map !== "-1") {
        finalUrl += `&is_map=${map}`
      }
      if( customerpolicyId){
        finalUrl += `&customer_policy_id=${customerpolicyId}`
      }

      const res = await api.get(finalUrl);
      const data: ICSIORaw[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCSIOPolicyChangeListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCSIOPolicyChangeListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const FETCH_AUTOMOBILE_CSIO_APPLICATION_PROGRESS =
  "FETCH_AUTOMOBILE_CSIO_APPLICATION_PROGRESS";
export const FETCH_AUTOMOBILE_CSIO_APPLICATION_SUCCESSS =
  "FETCH_AUTOMOBILE_CSIO_APPLICATION_SUCCESSS";
export const FETCH_AUTOMOBILE_CSIO_APPLICATION_FAILED =
  "FETCH_AUTOMOBILE_CSIO_APPLICATION_FAILED";

export const fetchAutomobileCSIOApplicationProgress = () =>
  action(FETCH_AUTOMOBILE_CSIO_APPLICATION_PROGRESS);
export const fetchAutomobileCSIOApplicationSuccess = (
  data: IAutomobileApplication
) => action(FETCH_AUTOMOBILE_CSIO_APPLICATION_SUCCESSS, { data });
export const fetchAutomobileCSIOApplicationFailed = (error?: string) =>
  action(FETCH_AUTOMOBILE_CSIO_APPLICATION_FAILED, {error});

export const fetchAutomobileCSIOApplicationAsync =
  (
    messageGUid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
  //   try {
  //     dispatch(fetchAutomobileCSIOApplicationProgress());
  //     const res = await api.get(
  //       `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
  //     );
  //     const data = res.data.data;
  //     if (data.length > 0) {
  //       const { xml_json_message } = data[0];
  //       const parsedData = JSON.parse(xml_json_message);
  //       const result = transformCSIOToAutomobile(parsedData);
  //       dispatch(fetchAutomobileCSIOApplicationSuccess(result));
  //     } else {
  //       dispatch(fetchAutomobileCSIOApplicationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
  //     }
  //   } catch (err: any) {
  //     dispatch(fetchAutomobileCSIOApplicationFailed());
  //     dispatch(
  //       showMessage({
  //         type: "error",
  //         message: err.response.data.message,
  //         displayAs: "snackbar",
  //       })
  //     );
  //   }
  // };
  try {
    dispatch(fetchAutomobileCSIOApplicationProgress());
    const res = await api.get(
      `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
    );
    console.log("API response:", res);
    
    if (res && res.data && res.data.data) {
      const data = res.data.data;
      console.log("AutomobileData:", data);
      
      if (data.length > 0) {
        const { xml_json_message } = data[0];
        console.log("xml_json_message:", xml_json_message);
        
        try {
          const parsedData = JSON.parse(xml_json_message);
          console.log("parsedData:", parsedData);
          const result = transformCSIOToAutomobile(parsedData);
          console.log("result:", result);
          dispatch(fetchAutomobileCSIOApplicationSuccess(result));
        } catch (parseError) {
          console.error("JSON parse error:", parseError);
          dispatch(fetchAutomobileCSIOApplicationFailed("Failed to parse JSON message"));
        }
      } else {
        console.log("No record found.");
        dispatch(fetchAutomobileCSIOApplicationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } else {
      console.log("No record found in response data.");
      dispatch(fetchAutomobileCSIOApplicationFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
    }
  } catch (err: any) {
    console.error("Fetch error:", err);
    dispatch(fetchAutomobileCSIOApplicationFailed());
    dispatch(
      showMessage({
        type: "error",
        message: err.response?.data?.message || "An error occurred",
        displayAs: "snackbar",
      })
    );
  }
};

export const addAutomobileCSIOApplication =
  (
    data: IAutomobileApplication,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-auto-automobile-application", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Application saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileCSIOApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };



export const CLEAR_AUTOMOBILE_CSIO_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_CSIO_APPLICATION_STATE";

export const clearAutomobileCSIOApplicationState = () =>
  action(CLEAR_AUTOMOBILE_CSIO_APPLICATION_STATE);
