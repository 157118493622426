import React from "react";
import { Avatar, Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBreadcrumbContext } from "../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { IStoreState } from "../../../redux/initialStoreState";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import {
  fetchNoteAsync,
  fetchNoteListAsync,
  fetchNoteListFromPolicyAsync,
} from "../../../redux/notes/notesActions";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { INotices } from "../../../redux/notices/notices.types";
import { DataTable } from "../../../components/Table/DataTable";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { IPolicyNote } from "../../../redux/notes/notes.types";
import { StandadCard } from "../../../components/Cards";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RefreshPlugin, SearchPlugin } from "../../../components/Table/plugins";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { CustomSelect } from "../../../components/formsComponents";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { capitalizeWords, createTabsWithRecordcounts } from "../../../helpers";
import { PageContainer } from "../../../components/container/PageContainer";
import { ITableTab } from "../../../components/Table/components/TableTabs/TableTabs.types";
import { PageTitleBar } from "../../../components/PageTitleBar/PageTitleBar";
import { Add } from "@mui/icons-material";

export const TaskNotesList: React.FC = () => {
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard/",
      title: "Dashboard",
    },
    {
      title: "Abeyances/Notes",
    },
  ];
  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.policyNotes
  );
  const [tableTabs, setTableTabs] = React.useState<ITableTab[]>([]);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "allTimes" });

  const [searchType, setSearchType] = React.useState<string[]>(["notes_title"]);
  const [search, setSearch] = React.useState<string>("");
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "-1"
  );
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const fetchList = async () => {
    dispatch(
      fetchNoteListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, search, status]);

  const handleRedirect = () => {
    navigate(`/manage-note`);
  };

  const handleEditRedirect =
    (customerPolicyId: number, serialNumber: number) => () => {
      navigate(`/manage-note/${customerPolicyId}/${serialNumber}`);
    };

  const notesTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
       
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
                moduleId={MODULE_IDS.NOTES}
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Customer Name", value: "customer" },
                    { label: "Title", value: "notes_title" },
                    { label: "Policy Number", value: "policy_number" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        isFirstColumnSticky: true,
        renderType: RenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row: IPolicyNote) => {
          return (
            <StandardTableActions
              commentBoxTypeId={
                row.customer_policy_id + "-" + row.incremental_number
              }
              onEditClick={handleEditRedirect(
                row.customer_policy_id,
                row.incremental_number as number
              )}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status === "ACTIVE"? "Open": "Closed"} />;
        },
      },
      {
        key: "notes_title",
        headerName: "Title",
        fieldName: "notes_title",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={handleEditRedirect(
                row.customer_policy_id,
                row.incremental_number as number
              )}
            >
              {row.notes_title}
            </Link>
          );
        },
      },
      {
        key: "customer",
        headerName: "Customer",
        fieldName: "customer",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.CHIP_WARNING,
        enableSorting: true,
        exportCellWidth: 30,
      },
      {
        key: "date_created",
        headerName: "Date Created",
        fieldName: "insert_ts",
        renderType: RenderType.DATE,
        enableSorting: true,
        exportCellWidth: 20,
      },
      {
        key: "date_created",
        headerName: "Date Modifed",
        fieldName: "date_created",
        renderType: RenderType.DATE,
        enableSorting: true,
        exportCellWidth: 20,
      },
      {
        key: "time_completed",
        headerName: "Date Completed",
        fieldName: "time_completed",
        renderType: RenderType.DATE_TIME,
        enableSorting: true,
        exportCellWidth: 20,
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    tableTabProps: {
      selectedTab: status,
      tabs: tableTabs,
      onTabChange: (newSelectedTab) => {
        setStatus(newSelectedTab);
      },
    },
  };

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Open", value: "ACTIVE", variant: "success" },
        { label: "Closed", value: "INACTIVE", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_notes",
        "status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  return (
    <PageContainer title="View Abeyances/Notes" description="this is innerpage">
      <Breadcrumb title="" items={BCrumb} />

  

      <PageTitleBar
        heading="Abeyances/Notes"
        rightHeading={
          <Button variant="contained" startIcon={<Add/>} onClick={handleRedirect}>
           Create
          </Button>
        }
      />
        <DataTable {...notesTableProps} />

    </PageContainer>
  );
};
