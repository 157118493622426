/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccountBox,
  ContactPage,
  FiberManualRecordOutlined,
  TrackChanges,
} from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IStoreState } from "../../../../redux/initialStoreState";
import { batch, useSelector } from "react-redux";
import { StandadCard } from "../../../../components/Cards";
import { useDispatchWrapper } from "../../../../hooks";
import {
  clearCustomerState,
  fetchCustomerProspectAsync,
  fetchCustomerRelAccountAsync,
  fetchCustomerSocialAsync,
  fetchPersonalInfoAsync,
} from "../../../../redux/customer/customerActions";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { TabPanel } from "../../../../components/Tabs/TabPanel";
import { TabsList } from "../../../../components/Tabs/TabsList";
import { CustomChip } from "../../../../components/CustomChip";
import profilePhoto from "../../../../assets/user-1.jpg";
import {
  IDataTableProps,
  RenderType,
} from "../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../components/Table/DataTable";
import { getFullAddres } from "../../../../helpers";
import { StatusRenderer } from "../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";

export const CustomerBaiscDetailsLayout: React.FC<{
  customerId?: string | number;
  fromPolicy: boolean;
  policyId?: number;
  children: React.ReactNode;
}> = (props) => {
  const { customerId, fromPolicy, policyId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  const {
    personalInformation: { data, loading },
    customerProspect,
    customerSocial,
    customerRelatedAccount,
    error,
  } = useSelector((storeState: IStoreState) => storeState.customer);

  const [tab, setTab] = React.useState(0);
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 2,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleEdit = (id: number | null) => () => {
    navigate("/edit-customer/" + id);
  };

  const customerRefTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: customerRelatedAccount.data.length,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    paginationList: [2, 5, 10, 25, 50],
    // rowsPerPageOptions: 1000,
    isDataLoading: customerRelatedAccount.loading === LoadState.InProgress,

    uniqueRowKeyName: "key",
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
      },

      {
        key: "cross_reference_id",
        headerName: "Cross Reference Id",
        fieldName: "cross_reference_id",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 15,
      },
      {
        key: "name",
        headerName: "Customer Name",
        fieldName: "relative_name",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 15,
        // onRowCellRender: (value, row) => {
        //   return (
        //     <CustomLink
        //       to={
        //         "/customer-overview/" +
        //         (row as ICustomerRelatedAccount).cross_reference_id
        //       }
        //       label={value.value}
        //     />
        //   );
        // },
      },
      {
        key: "customer_code",
        headerName: "Customer Code",
        fieldName: "customer_code",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 15,
      },
    ],
    items: customerRelatedAccount.data.slice(
      (tablePagination.pageNumber - 1) * tablePagination.rowsInPerPage,
      tablePagination.pageNumber * tablePagination.rowsInPerPage
    ),

    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  React.useEffect(() => {
    if (customerId) {
      batch(() => {
        dispatch(
          fetchPersonalInfoAsync(customerId as number, fromPolicy, policyId)
        );
        dispatch(
          fetchCustomerProspectAsync(customerId as number, fromPolicy, policyId)
        );
        dispatch(
          fetchCustomerSocialAsync(customerId as number, fromPolicy, policyId)
        );
        dispatch(
          fetchCustomerRelAccountAsync(
            customerId as number,
            fromPolicy,
            policyId
          )
        );
      });
    }
  }, [customerId, fromPolicy, policyId]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCustomerState());
    };
  }, []);

  const CustomerStatus = () => {
    if (customerProspect.data.status === "ACTIVE") {
      return (
        <CustomChip
          content={customerProspect.data.status}
          size="small"
          color="success"
          icon={<FiberManualRecordOutlined />}
        />
      );
    } else if (customerProspect.data.status === "INACTIVE") {
      return (
        <CustomChip
          content={customerProspect.data.status}
          size="small"
          color="error"
          icon={<FiberManualRecordOutlined />}
        />
      );
    } else {
      return (
        <CustomChip
          content={customerProspect.data.status}
          size="small"
          color="warning"
          icon={<FiberManualRecordOutlined />}
        />
      );
    }
  };

  return (
    <PageLoader
      loading={loading === LoadState.InProgress}
      error={
        error
          ? {
              message: error,
            }
          : null
      }
    >
      <Box>
        {data && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <StandadCard>
                  <Box display={"flex"} justifyContent={"end"}>
                    {/* {CustomerStatus()} */}
                    <StatusRenderer status={customerProspect.data.status} />
                  </Box>
                  <Box display={"flex"} justifyContent={"center"}>
                    {/* <AccountCircle
                      sx={(theme: Theme) => ({
                        fontSize: "140px",
                        color:
                          theme.palette.mode === "dark"
                            ? "#fff"
                            : "rgba(0, 0, 0, 0.12)",
                      })}
                    /> */}
                    <img
                      src={profilePhoto}
                      alt="log not found"
                      style={{ height: "150px", borderRadius: "100px" }}
                    />
                  </Box>
                  <Stack direction="column" spacing={1} alignItems="center">
                    <Typography variant="h5" marginBottom={0} fontWeight={600}>
                      {customerProspect.data.customer_name}
                    </Typography>
                    <Typography variant="body1">
                      {" "}
                      {`(#${customerProspect.data.customer_code || "N/A"})`}
                    </Typography>

                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{ mr: 1 }}
                        onClick={handleEdit(data.customer_id)}
                      >
                        View Profile
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{ ml: 1 }}
                        onClick={handleEdit(data.customer_id)}
                      >
                        Edit Profile
                      </Button>
                    </Box>
                    {/* <Stack direction="row" spacing={2}>
                      <Button variant="contained">
                        {" "}
                        <FacebookIcon />{" "}
                      </Button>
                      <Button variant="contained">
                        {" "}
                        <FacebookIcon />{" "}
                      </Button>
                      <Button variant="contained">
                        {" "}
                        <FacebookIcon />{" "}
                      </Button>
                    </Stack> */}
                  </Stack>
                  {/* </Grid>
                </Grid> */}
                </StandadCard>
              </Grid>

              <Grid item xs={12} lg={9}>
                <StandadCard sx={{ height: "100%" }}>
                  <TabsList
                    varaint="fullWidth"
                    selectedTab={tab}
                    sx={{ mb: 2 }}
                    tabs={[
                      {
                        label: "Customer",
                        icon: <TrackChanges />,
                      },
                      { label: "Information", icon: <ContactPage /> },
                      {
                        label: "Social",
                        icon: <AccountBox />,
                      },
                      {
                        label: "Related Accounts",
                        icon: <AccountBox />,
                      },
                    ]}
                    onChange={handleChange}
                  />
                  <TabPanel value={tab} index={0}>
                    <Grid container spacing={2} sx={{ py: 1 }}>
                      <Grid item xs={3}>
                        <Row
                          heading="Customer Name"
                          value={
                            customerProspect.data.customer_name +
                            ` (${customerProspect.data.customer_code})`
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Form of Business Name"
                          value={customerProspect.data.form_of_business}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Tax Zone"
                          value={customerProspect.data.tax_zone}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Language"
                          value={customerProspect.data.language}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Producer"
                          value={customerProspect.data.producer_code}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Marketer"
                          value={customerProspect.data.marketer_code}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Branch"
                          value={customerProspect.data.branch_code}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Assigned To"
                          value={customerProspect.data.assigned_to_code}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="CSR"
                          value={customerProspect.data.csr_code}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Mailing Address"
                          value={getFullAddres(
                            customerProspect.data.mailing_address,
                            customerProspect.data.unit_or_suite,
                            customerProspect.data.city,
                            customerProspect.data.province_or_state,
                            customerProspect.data.country,
                            customerProspect.data.postal_code
                          )}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <Grid container spacing={2} sx={{ height: "100%" }}>
                      <Grid item xs={3}>
                        <Row
                          heading="Customer Name"
                          value={
                            (data.salutation || "") +
                            " " +
                            data.first_name +
                            " " +
                            data.last_name
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row heading="Phone Number" value={data.phone} />
                      </Grid>
                      <Grid item xs={3}>
                        <Row heading="Ext" value={data.phone_ext} />
                      </Grid>
                      <Grid item xs={3}>
                        <Row heading="Email" value={data.email} />
                      </Grid>
                      <Grid item xs={6}>
                        <Row
                          heading="Address"
                          value={
                            customerProspect.data.mailing_address +
                            ", " +
                            customerProspect.data.unit_or_suite +
                            ", " +
                            customerProspect.data.city +
                            ", " +
                            customerProspect.data.province_or_state +
                            ", " +
                            customerProspect.data.postal_code
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row heading="Goes by" value={data.goes_by} />
                      </Grid>
                      <Grid item xs={3}>
                        <Row heading="Position" value={data.position} />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Branch"
                          value={customerProspect.data.branch_code}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Producer"
                          value={customerProspect.data.producer_code}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="CSR"
                          value={customerProspect.data.csr_code}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Row
                          heading="Assigned To"
                          value={customerProspect.data.assigned_to_code}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={tab} index={2}>
                    <Grid container spacing={2} sx={{ py: 1 }}>
                      <Grid item xs={4}>
                        <Row
                          heading="Website"
                          value={customerSocial.data.website}
                          isLink
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Row
                          heading="Facebook"
                          value={customerSocial.data.facebook}
                          isLink
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Row
                          heading="Linkedin"
                          value={customerSocial.data.linkedin}
                          isLink
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tab} index={3}>
                    <DataTable {...customerRefTableProps} />
                  </TabPanel>
                </StandadCard>
              </Grid>
            </Grid>

            {/* <Divider sx={{ mt: 3, mb: 3 }} /> */}
          </Box>
        )}

        {props.children}
      </Box>
    </PageLoader>
  );
};

const Row: React.FC<{
  heading: string;
  value: string | null | number;
  isLink?: boolean;
}> = (props) => {
  const { isLink = false } = props;
  return (
    <Box display={"block"} justifyContent={"space-between"}>
      <Typography fontWeight={500}>{props.heading}</Typography>
      {isLink && props.value ? (
        <>
          <Typography
            component={"a"}
            fontSize={16}
            sx={{ textDecoration: "none" }}
            href={props.value.toString()}
            color={"primary.main"}
            target="_blank"
          >
            {props.value}
          </Typography>
        </>
      ) : (
        <Typography color={"textSecondary"}>
          {props.value ? props.value : "N/A"}
        </Typography>
      )}
    </Box>
  );
};
