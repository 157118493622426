import { LoadState } from "../../constants/enums";
import { INoPolicyInsuranceReports } from "../../views/tasks/InsuranceReports/insuranceReport.types";

export interface IInsuranceReportsState {
  list: IGroupedInsuranceReports[];
  noPolicyList: INoPolicyInsuranceReports[];
  totalRecords: number;
  noPolicyTotalRecords: number;
  loading: LoadState;
  noPolicyLoading: LoadState;
  data: INoPolicyInsuranceReports;
  dataLoading: LoadState;
  error: string | null;
}

export interface IGroupedInsuranceReports extends IInsuranceReports {
  childs: IInsuranceReports[];
}

export interface IInsuranceReports {
  customer_policy_id: number;
  customer_id: number;
  incremental_number: number | null;
  driver_name: string | null;
  license_number: string | null;
  business_reason: string | null;
  date_and_time: string | null;
  days_left: string | null;
  transaction_type: string | null;
  report_requested: string | null;
  request_status: string;
  insurer_code: string | null;
  intact_branch: string | null;
  number_of_disclosed_convictions: string | null;
  policy_number: string | null;
  policy_status_cancelled: string | null;
  policy_type: string | null;
  requested_by: string | null;
  assigned_to: string | null;
  branch: string | null;
  producer: string | null;
  csr: string | null;
  notes: string | null;
  file_upload: string | null;
  date_wriitten: string | null;
  date_destroyed: string | null;
  date_voided: string | null;
  date_cancelled: string | null;
  status: string;
  producer_one_code: string | null,
  csr_code: string | null,
  insert_ts: string | null,
}

export const initialInsuranceReportsState: IInsuranceReportsState = {
  list: [],
  noPolicyList: [],
  totalRecords: 0,
  noPolicyTotalRecords: 0,
  loading: LoadState.NotLoaded,
  noPolicyLoading: LoadState.NotLoaded,
  data: {
    customer_name: null,
    customer_code: null,
    driver_name: null,
    license_number: null,
    business_reason: "Verify Driver Record",
    date_and_time: null,
    days_left: null,
    transaction_type: null,
    report_requested: null,
    request_status: null,
    insurer_code: null,
    intact_branch: null,
    number_of_disclosed_convictions: null,
    policy_number: null,
    insurer_code_written: null,
    customer_id: null,
    customer_policy_id: null,
    policy_status_cancelled: null,
    requested_by: null,
    requested_by_code: null,
    assigned_to: null,
    branch: null,
    producer: null,
    producer_code: null,
    producer_one_code: null,
    csr: null,
    csr_code: null,
    notes: null,
    file_type: null,
    file_type_two: null,
    file_upload: null,
    file_upload_two: null,
    date_wriitten: null,
    date_destroyed: null,
    date_voided: null,
    date_cancelled: null,
    status: "ACTIVE",
    incremental_number: null,
    years_at_this_address: null,
    current_address_type: null,
    current_address: null,
    current_address_unit_or_suite: null,
    current_address_city: null,
    current_address_state_or_province: null,
    current_address_postal_code: null,
    current_address_country: null,
    new_address_type: null,
    new_address: null,
    new_address_unit_or_suite: null,
    new_address_city: null,
    new_address_state_or_province: null,
    new_address_postal_code: null,
    new_address_country: null,
},
  dataLoading: LoadState.NotLoaded,
  error: null,
};
