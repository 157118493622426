import styled from "@emotion/styled";
import { Alert, AlertProps } from "@mui/material";

export const CustomAlert = styled((props: AlertProps) => <Alert {...props} />)(
  () => ({
    "& .MuiAlert-icon": {
        color: "#ef5350"
      },
      "& .MuiAlert-message": {
            color: "rgb(95, 33, 32)"
      }
  })
);
