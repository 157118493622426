import { LoadState } from "../../constants/enums";

export interface ILegalsState {
    list: ILegal[];
    totalRecords: number;
    loading: LoadState;
    legal: ILegal;
    legalLoading: LoadState;
    errro: string | null;
}

export interface ILegal {
    incremental_number: number | null;
    legal_plus_code: number | null; 
    type: string | null;
    defendant: string;
    plaintiff: string;
    customer_name: string;
    policy_number: number | null;
    date_of_underlying_loss: string | null;
    date_claim_made_against_brokerage_agency: string | null;
    date_reported: string | null;
    carrier_Name : string | null;
    claim_status : string | null;
    demand_amount : string | null;
    claim_expenses_paid: string | null;
    claim_expensed_reserved: string | null;
    loss_reserve: string | null;
    loss_paid: string | null;
    description_of_alleged_act: string | null;
    effective_date: string | null;
    expiry_date: string | null;
    details: string;
    attachment: string | null;
    comments: string | null;
    status: string; 
}

export const initialLegal: ILegal = {
    incremental_number: null,
    legal_plus_code:  null, 
    type: null,
    defendant: "",
    plaintiff: "",
    customer_name: "",
    policy_number: null,
    date_of_underlying_loss: null,
    date_claim_made_against_brokerage_agency: null,
    date_reported: null,
    carrier_Name : null,
    claim_status: null,
    demand_amount : null,
    claim_expenses_paid: null,
    claim_expensed_reserved: null,
    loss_reserve: null,
    loss_paid: null,
    description_of_alleged_act: null,
    effective_date: null,
    expiry_date: null,
    details: "",
    attachment: null, 
    comments: "",
    status: "ACTIVE",
}

export const initialLegalState: ILegalsState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    legal: initialLegal,
    legalLoading: LoadState.NotLoaded,
    errro: null,
}