import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import { IDataTableProps, RenderType } from "../../../../../components/Table/interfaces/IDataTableProps";
import { fetchNoteListFromPolicyAsync } from "../../../../../redux/notes/notesActions";
import { LoadState } from "../../../../../constants/enums";
import { INotices } from "../../../../../redux/notices/notices.types";
import { StatusRenderer } from "../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { StandardTableActions } from "../../../../../components/StandardTableActions/StandardTableActions";
import { IPolicyNote } from "../../../../../redux/notes/notes.types";
import { DataTable } from "../../../../../components/Table/DataTable";
import { clearBusinessPolicyRestrutant } from "../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { Add } from "@mui/icons-material";

export const NotesList: React.FC<{
  customerId: number,
  customerPolicyId: number;
  onAddEditClick: (incrementalNumber?: number | null) => void;
}> = (props) => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerPolicyId = data1[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: "Abeyances/Notes",
    },
  ];
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.policyNotes
  );
  const dispatch = useDispatchWrapper();
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, []);
  React.useEffect(() => {
    dispatch(fetchNoteListFromPolicyAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const notesTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                startIcon={<Add/>}
                onClick={() => {
                  props.onAddEditClick()
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: INotices) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                props.onAddEditClick(row.incremental_number)
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "notes_title",
        headerName: "Title",
        fieldName: "notes_title",
        enableSorting: true,
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "customer",
        headerName: "Customer",
        fieldName: "customer",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        enableSorting: true,
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 30,

      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status === "ACTIVE" ? "Open": "Closed"} />;
        },
      },
      {
        key: "date_created",
        headerName: "Date Created",
        fieldName: "insert_ts",
        renderType: RenderType.DATE,
        enableSorting: true,
        exportCellWidth: 20,
      },
      {
        key: "date_created",
        headerName: "Date Modifed",
        fieldName: "date_created",
        renderType: RenderType.DATE,
        enableSorting: true,
        exportCellWidth: 20,
      },
      {
        key: "time_completed",
        headerName: "Date Completed",
        fieldName: "time_completed",
        enableSorting: true,
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 20,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IPolicyNote) => {
          return (
            <StandardTableActions
              commentBoxTypeId={row.customer_policy_id + '-' + row.incremental_number}
              onEditClick={() => {
                props.onAddEditClick(row.incremental_number)
              }}
            />
          );
        },
      },
    ],
    items: list
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...notesTableProps} />
      </Box>
    </>
  );
};
