import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getUserAuth } from "../../helpers";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import {
  ICustomerConsent,
  ICustomerContact,
  ICustomerFullDetails,
  ICustomerGroup,
  ICustomerProspect,
  ICustomerRelatedAccount,
  ICustomerSocial,
  IOpportunity,
  IPersonalInformation,
} from "./customer.types";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { LoadState } from "../../constants/enums";
import { initialCustomerState } from "./customerState";
import { IPolicy } from "../../views/Policies/Policies.types";
import { makeApiCall } from "../../helpers/postRequest";

export const CLEAR_CUSTOMER_STATE = "CLEAR_CUSTOMER_STATE";
export const RECORD_NOT_FOUND = "RECORD_NOT_FOUND";

export const ADD_CUSTOMER_SOCIAL = "ADD_CUSTOMER_SOCIAL";
export const FETCH_CUSTOMER_SOCIAL_PROGRESS = "FETCH_CUSTOMER_SOCIAL_PROGRESS";

export const ADD_CUSTOMER_REL_ACCOUNT = "ADD_CUSTOMER_REL_ACCOUNT";
export const FETCH_CUSTOMER_REL_ACCOUNT_PROGRESS =
  "FETCH_CUSTOMER_REL_ACCOUNT_PROGRESS";

export const ADD_CUSTOMER_CONSENT = "ADD_CUSTOMER_CONSENT";
export const FETCH_CUSTOMER_CONSENT_PROGRESS =
  "FETCH_CUSTOMER_CONSENT_PROGRESS";

export const FETCH_OPPPRTUNITY_PROGRESS = "FETCH_OPPPRTUNITY_PROGRESS";
export const ADD_OPPPRTUNITY = "ADD_OPPPRTUNITY";

export const FETCH_CUSTOMER_FULL_DETAILS_PROGRESS =
  "FETCH_CUSTOMER_FULL_DETAILS_PROGRESS";
export const ADD_CUSTOMER_FULL_DETAILS = "ADD_CUSTOMER_FULL_DETAILS";
export const FETCH_CUSTOMER_FULL_DETAILS_FAILED =
  "FETCH_CUSTOMER_FULL_DETAILS_FAILED";

export const FETCH_CUSTOMER_CONSENT_LIST_PROGRESS =
  "FETCH_CUSTOMER_CONSENT_LIST_PROGRESS";
export const FETCH_CUSTOMER_CONSENT_LIST_SUCCESS =
  "FETCH_CUSTOMER_CONSENT_LIST_SUCCESS";
export const FETCH_CUSTOMER_CONSENT_LIST_FAILED =
  "FETCH_CUSTOMER_CONSENT_LIST_FAILED";

export const FETCH_CUSTOMER_GROUP_LIST_PROGRESS =
  "FETCH_CUSTOMER_GROUP_LIST_PROGRESS";
export const FETCH_CUSTOMER_GROUP_LIST_SUCCESS =
  "FETCH_CUSTOMER_GROUP_LIST_SUCCESS";
export const FETCH_CUSTOMER_GROUP_LIST_FAILED =
  "FETCH_CUSTOMER_GROUP_LIST_FAILED";

export const clearCustomerState = () => action(CLEAR_CUSTOMER_STATE);
export const recordNotFound = () => action(RECORD_NOT_FOUND);

export const ADD_PERSONAL_INFORMATION = "ADD_PERSONAL_INFORMATION";
export const FETCH_PERSONAL_INFO_PROGRESS = "FETCH_PERSONAL_INFO_PROGRESS";

export const fetchPersonalInfoProgress = () =>
  action(FETCH_PERSONAL_INFO_PROGRESS);
export const fetchPersonalInfoAsync =
  (
    customerId: number,
    fromPolicy: boolean,
    policyId?: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().customer.personalInformation.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchPersonalInfoProgress());
        let url = `/customer/get-customer-personal-information?customer_id=${customerId}`;
        if (fromPolicy && policyId) {
          url = `/customer/get-customer-information-security?customer_policy_id=${policyId}`;
        }
        const res = await api.get(url);
        const data: IPersonalInformation[] = res.data.data;
        if (data.length > 0) {
          dispatch(addPersonalInformation(data[0]));
        } else {
          dispatch(recordNotFound());
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addPersonalInformation = (data: IPersonalInformation) =>
  action(ADD_PERSONAL_INFORMATION, { data: data });
export const addPersonalInformationAsync =
  (
    data: IPersonalInformation,
    initialData: IPersonalInformation,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await makeApiCall(
        {
          url: "/customer/edit-customer-personal-information",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "customer_id",
          },
        },
        {
          first_name: initialData.first_name,
          email: initialData.email,
          phone: initialData.phone,
          salutation: initialData.salutation,
          last_name: initialData.last_name,
          goes_by:
            initialData.goes_by === ""
              ? initialData.first_name
              : initialData.goes_by,
          position: initialData.position,
          mobile: initialData.mobile,
          phone_ext: initialData.phone_ext,
          status: initialData.status,
          customer_name: initialData.first_name + " " + initialData.last_name,
          customer_id: initialData.customer_id,
        },
        {
          first_name: data.first_name,
          email: data.email,
          phone: data.phone,
          salutation: data.salutation,
          last_name: data.last_name,
          goes_by: data.goes_by === "" ? data.first_name : data.goes_by,
          position: data.position,
          mobile: data.mobile,
          phone_ext: data.phone_ext,
          status: data.status,
          customer_name: data.first_name + " " + data.last_name,
          customer_id: data.customer_id,
        }
      );

      dispatch(addPersonalInformation(data));

      dispatch(
        showMessage({
          type: "success",
          message: "Personal information saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
      // dispatch(fetchPersonalInfoAsync(data.customer_id));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const addPersonalInformationDialog = (data: IPersonalInformation) =>
  action(ADD_PERSONAL_INFORMATION, { data: data });
export const addPersonalInformationDialogAsync =
  (
    data: IPersonalInformation,
    initialData: IPersonalInformation,
    isUpdate: boolean,
    onCallback: (
      isSuccess: boolean,
      customerId?: IPersonalInformation["customer_id"]
    ) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await makeApiCall(
        {
          url: "/customer/edit-customer-personal-information",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "customer_id",
          },
        },
        initialData,
        {
          first_name: data.first_name,
          email: data.email,
          phone: data.phone,
          salutation: data.salutation,
          last_name: data.last_name,
          goes_by: data.goes_by,
          position: data.position,
          mobile: data.mobile,
          phone_ext: data.phone_ext,
          status: data.status,
          customer_name: data.first_name + " " + data.last_name,
          customer_id: data.customer_id,
        }
      );

      const customer: IPersonalInformation = res.data.data;

      dispatch(
        showMessage({
          type: "success",
          message: "Personal information saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true, customer.customer_id);
      // dispatch(fetchPersonalInfoAsync(data.customer_id));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const ADD_CUSTOMER_PROSPECT = "ADD_CUSTOMER_PROSPECT";
export const FETCH_CUSTOMER_PROSPECT_PROGRESS =
  "FETCH_CUSTOMER_PROSPECT_PROGRESS";

export const fetchCustomerProspectProgress = () =>
  action(FETCH_CUSTOMER_PROSPECT_PROGRESS);
export const addCustomerProspect = (data: ICustomerProspect) =>
  action(ADD_CUSTOMER_PROSPECT, { data: data });
export const addCustomerProspectAsync =
  (
    data: ICustomerProspect,
    initialData: ICustomerProspect,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await makeApiCall(
        {
          url: "/customer/edit-customer-prospact-details",
          method: "POST",
          automation: {
            isUpdate: true,
            primaryFieldName: "customer_id",
          },
        },
        initialData,
        data
      );

      dispatch(addCustomerProspect(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Customer propspect saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCustomerProspectAsync =
  (
    customerId: number,
    fromPolicy: boolean,
    policyId?: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().customer.customerProspect.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCustomerProspectProgress());
        let url = `/customer/get-customer-prospact-details?customer_id=${customerId}`;
        if (fromPolicy && policyId) {
          url = `/customer/get-customer-prospect-details-security?customer_policy_id=${policyId}`;
        }
        const res = await api.get(url);
        const data: ICustomerProspect[] = res.data.data;
        if (data.length > 0) {
          dispatch(addCustomerProspect(data[0]));
        } else {
          dispatch(
            addCustomerConsent(initialCustomerState["customerConsent"]["data"])
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addCustomerSocial = (data: ICustomerSocial) =>
  action(ADD_CUSTOMER_SOCIAL, { data: data });

export const addCustomerSocialAsync =
  (
    data: ICustomerSocial,
    initialData: ICustomerSocial,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await makeApiCall(
        {
          url: "/customer/edit-customer-social",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "customer_id",
          },
        },
        initialData,
        data
      );

      dispatch(addCustomerSocial(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Customer social saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCustomerSocialProgress = () =>
  action(FETCH_CUSTOMER_SOCIAL_PROGRESS);

export const fetchCustomerSocialAsync =
  (
    customerId: number,
    fromPolicy: boolean,
    policyId?: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().customer.customerSocial.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCustomerSocialProgress());
        let url = `/customer/get-customer-social?customer_id=${customerId}`;
        if (fromPolicy && policyId) {
          url = `/customer/get-customer-social-security?customer_policy_id=${policyId}`;
        }
        const res = await api.get(url);
        const data: ICustomerSocial[] = res.data.data;
        if (data.length > 0) {
          dispatch(addCustomerSocial(data[0]));
        } else {
          dispatch(
            addCustomerSocial(initialCustomerState["customerSocial"]["data"])
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addCustomerConsent = (data: ICustomerConsent) =>
  action(ADD_CUSTOMER_CONSENT, { data: data });

export const aaddCustomerConsentAsync =
  (
    data: ICustomerConsent,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/customer/edit-customer-consent", {
        ...data,
        status: "ACTIVE",
      });
      dispatch(addCustomerConsent(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Customer consent saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCustomerConsentProgress = () =>
  action(FETCH_CUSTOMER_CONSENT_PROGRESS);

export const fetchCustomerConsentAsync =
  (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().customer.customerConsent.loading === LoadState.Loaded;
    const token = getUserAuth();
    try {
      if (token && !isSaved) {
        dispatch(fetchCustomerConsentProgress());
        const res = await api.get(
          `/customer/get-customer-consent?customer_id=${customerId}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        const data: ICustomerConsent[] = res.data.data;
        if (data.length > 0) {
          dispatch(addCustomerConsent(data[0]));
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addCustomerRelAccount = (data: ICustomerRelatedAccount[]) =>
  action(ADD_CUSTOMER_REL_ACCOUNT, { data: data });

export const addCustomerRelAccountlAsync =
  (
    data: ICustomerRelatedAccount,
    initialData: ICustomerRelatedAccount,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await makeApiCall(
        {
          url: "/customer/edit-customer-related-account",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "customer_id",
          },
        },
        initialData,
        data
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Customer related account saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCustomerRelAccountProgress = () =>
  action(FETCH_CUSTOMER_REL_ACCOUNT_PROGRESS);

export const fetchCustomerRelAccountAsync =
  (
    customerId: number,
    fromPolicy: boolean,
    policyId?: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCustomerRelAccountProgress());
      dispatch(fetchCustomerConsentProgress());
      let url = `/customer/get-customer-related-account?customer_id=${customerId}`;
      if (fromPolicy && policyId) {
        url = `/customer/get-customer-related-account-security?customer_policy_id=${policyId}`;
      }
      const res = await api.get(url);
      const data: ICustomerRelatedAccount[] = res.data.data;
      if (data.length > 0) {
        dispatch(addCustomerRelAccount(data));
      } else {
        dispatch(
          addCustomerRelAccount(
            initialCustomerState["customerRelatedAccount"]["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchOpprtunitiesProgress = () =>
  action(FETCH_OPPPRTUNITY_PROGRESS);

export const addOpprtunities = (data: IOpportunity[], totalRecords: number) =>
  action(ADD_OPPPRTUNITY, { data, totalRecords });

export const fetchOpprtunity =
  (
    customerId: number,
    pageNumber: number,
    rowsInPerPage: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      if (token) {
        dispatch(fetchOpprtunitiesProgress());
        const res = await api.get(
          `/lead/get-opportunity?customer_id=${customerId}&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        const data: IOpportunity[] = res.data.data;
        dispatch(addOpprtunities(data, res.data.totalRecords));
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchCustomerFullDetailsProgress = () =>
  action(FETCH_CUSTOMER_FULL_DETAILS_PROGRESS);
export const addCustomerFullDetails = (
  customer: ICustomerFullDetails,
  customerId: number
) => action(ADD_CUSTOMER_FULL_DETAILS, { customer, customerId });

export const fetchCustomerConsentListProgress = () =>
  action(FETCH_CUSTOMER_CONSENT_LIST_PROGRESS);

export const fetchCustomerConsentListSuccess = (data: ICustomerConsent[]) =>
  action(FETCH_CUSTOMER_CONSENT_LIST_SUCCESS, { data });

export const fetchCustomerConsentListFailed = () =>
  action(FETCH_CUSTOMER_CONSENT_LIST_FAILED);

export const fetchCustomerConsentListAsync =
  (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCustomerConsentListProgress());
      const res = await api.get(
        `/customer/get-customer-consent?customer_id=${customerId}`
      );
      const data: ICustomerConsent[] = res.data.data;
      dispatch(fetchCustomerConsentListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCustomerConsentListFailed());
    }
  };

export const editCustomerConsentAsync =
  (
    data: ICustomerConsent,
    onCallback: (isSucces?: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/customer/edit-customer-consent", {
        ...data,
        status: "ACTIVE",
      });

      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );

      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCustomerGroupListProgress = () =>
  action(FETCH_CUSTOMER_GROUP_LIST_PROGRESS);

export const fetchCustomerGroupListSuccess = (data: ICustomerGroup[]) =>
  action(FETCH_CUSTOMER_GROUP_LIST_SUCCESS, { data });

export const fetchCustomerGroupListFailed = () =>
  action(FETCH_CUSTOMER_GROUP_LIST_FAILED);

export const fetchCustomerGroupListAsync =
  (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCustomerGroupListProgress());
      const res = await api.get(
        `/customer/get-customer-group?customer_id=${customerId}`
      );
      const data: ICustomerGroup[] = res.data.data;
      dispatch(fetchCustomerGroupListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCustomerGroupListFailed());
    }
  };

export const editCustomerGroupAsync =
  (
    data: ICustomerGroup,
    onCallback: (isSucces?: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/customer/edit-customer-group", {
        ...data,
        status: "ACTIVE",
      });

      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );

      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_CUSTOMER_CONTACTS_LIST_PROGRESS =
  "FETCH_CUSTOMER_CONTACTS_LIST_PROGRESS";
export const FETCH_CUSTOMER_CONTACTS_LIST_SUCCESS =
  "FETCH_CUSTOMER_CONTACTS_LIST_SUCCESS";
export const FETCH_CUSTOMER_CONTACTS_LIST_FAILED =
  "FETCH_CUSTOMER_CONTACTS_LIST_FAILED";

export const fetchCustomerContactsListProgress = () =>
  action(FETCH_CUSTOMER_CONTACTS_LIST_PROGRESS);

export const fetchCustomerContactsListSuccess = (data: ICustomerContact[]) =>
  action(FETCH_CUSTOMER_CONTACTS_LIST_SUCCESS, { data });

export const fetchCustomerContactsListFailed = () =>
  action(FETCH_CUSTOMER_CONTACTS_LIST_FAILED);

export const fetchCustomerContactsListAsync =
  (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCustomerContactsListProgress());
      const res = await api.get(
        `/customer/get-secondary-contact?customer_id=${customerId}`
      );
      const data: ICustomerContact[] = res.data.data;
      dispatch(fetchCustomerContactsListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCustomerGroupListFailed());
    }
  };

export const upsertCustomerContactAsync =
  (
    data: ICustomerContact,
    onCallback: (isSucces?: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/customer/create-secondary-contact", {
        ...data,
        status: "ACTIVE",
      });

      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );

      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ACTIVE_USER_POLICY_LIST_PROGRESS =
  "FETCH_ACTIVE_USER_POLICY_LIST_PROGRESS";
export const FETCH_ACTIVE_USER_POLICY_LIST_SUCCESS =
  "FETCH_ACTIVE_USER_POLICY_LIST_SUCCESS";
export const FETCH_ACTIVE_USER_POLICY_LIST_FAILED =
  "FETCH_ACTIVE_USER_POLICY_LIST_FAILED";

export const fetchActiveUserPolicyListProgress = () =>
  action(FETCH_ACTIVE_USER_POLICY_LIST_PROGRESS);
export const fetchActiveUserPolicyListSuccess = (data: IPolicy[]) =>
  action(FETCH_ACTIVE_USER_POLICY_LIST_SUCCESS, { data });
export const fetchActiveUserPolicyListFailed = () =>
  action(FETCH_ACTIVE_USER_POLICY_LIST_FAILED);

export const fetchActiveUserPolicyListAsync =
  (customerId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved = getState().customer.policies.loading;
    if (isSaved !== LoadState.Loaded) {
      try {
        dispatch(fetchActiveUserPolicyListProgress());
        const res = await api.get(
          `/policy/get-all-customer-policy-details?customer_id=${customerId}&status=ACTIVE`
        );
        const data: IPolicy[] = res.data.data;
        dispatch(fetchActiveUserPolicyListSuccess(data));
      } catch (err: any) {
        dispatch(fetchActiveUserPolicyListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    }
  };
