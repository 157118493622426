import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { IBusinessPolicyInterested } from "../../../../../../redux/businessPolicy/businessPolicyInterested/businessPolicyInterested.types";
import { fetchBusinessPolicyIntrestedListAsync } from "../../../../../../redux/businessPolicy/businessPolicyInterested/businessPolicyInterestedActions";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";

export const InterestedPartyList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.interested
  );
  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    dispatch(fetchBusinessPolicyIntrestedListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const interestedPartyProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/interested-party`
                  );
                }}
              >
                Add Interested Party
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyInterested) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/interested-party/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "risk_location",
        headerName: "Risk Location #",
        fieldName: "risk_location",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "interested_party_code",
        headerName: "Interest Party Code",
        fieldName: "interested_party_code",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },
      {
        key: "interested_party",
        headerName: "Interested Party",
        fieldName: "interested_party",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "interest",
        headerName: "Interest",
        fieldName: "interest",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },

     
      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        onRowCellValueRender: (value, row: IBusinessPolicyInterested) => {
          return (
            row.address +
            ", " +
            row.unit_or_suite +
            ", " +
            row.city +
            ", " +
            row.province_or_state+
            ", "+
            row.postal_code
          );
        },
      },
      {
        key: "insert_ts",
        headerName: "Created Date",
        fieldName: "insert_ts",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyInterested) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/interested-party/${row.incremental_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...interestedPartyProps} />
      </Box>
    </>
  );
};
