/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../../components/formsComponents";
import { useDispatchWrapper } from "../../../hooks";

import { useNavigate } from "react-router-dom";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { InitializeCSIOMappingProps } from "./InitializeCSIOMapping.types";
import { addInitializeMappingAsync } from "../../../redux/csio/csioActions";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { CustomDatePicker, CustomTimePicker } from "../../../components/formsComponents/CustomDatePicker";
import moment from "moment";

export const InitializeCSIOMapping: React.FC<InitializeCSIOMappingProps> = (
  props
) => {
  const { open, onClose } = props;

  const dispatch = useDispatchWrapper();
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "today" });
    const navigate = useNavigate();

  const { values, handleChange, handleSubmit, setValues, errors } = useFormik({
    initialValues: {
      from_date: "",
      from_time: "",
      to_date: "",
      to_time: "",
      only_for_unmapped: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.only_for_unmapped) {
        errors.only_for_unmapped = "*Record Type is required!";
      }
      return errors;
    },
    onSubmit: async (values) => {
     
      const fromTimePart = moment(values.from_time).format("HH:mm:ss");
      const combinedFromDateTime = moment(
        `${values.from_date} ${fromTimePart}`,
        "YYYY-MM-DDTHH:mm:ss.SSS"
      ).format(  "YYYY-MM-DDTHH:mm:ss.SSS")+"Z";


      const toTimePart = moment(values.to_time).format("HH:mm:ss");
      const combinedToDateTime = moment(
        `${values.to_date} ${toTimePart}`,
       "YYYY-MM-DDTHH:mm:ss.SSS"
      ).format("YYYY-MM-DDTHH:mm:ss.SSS")+"Z";

        dispatch(
          addInitializeMappingAsync(
             values.only_for_unmapped,combinedFromDateTime,combinedToDateTime,
            (isSuccess, code) => {
              if (isSuccess && code) {
                navigate("/csio-progress/" + code);
              }
           onClose();
            }
          )
        );
      }
    
  });


  return (
    <>
      <Dialog
        open={open}
        title="Initialize Mapping"
        onClose={onClose}
        size="sm"
        contentWrappedWithForm={{ onSubmit: handleSubmit }}
        actions={[
          { type: "button", label: "Close", variant: "text", onClick: onClose },
          {
            type: "submit",
            label: "Run",
            variant: "contained",
         
          },
        ]}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <CustomFormLabel>Record Type</CustomFormLabel>
            <ControlledCustomSelect
              name="only_for_unmapped"
              value={values.only_for_unmapped}
              onChange={handleChange}
              fullWidth
              displayEmpty
              placeholder="Select one"
              options={[
                { label: "Unmapped Record Only", value: "1" },
                { label: "All Records(Mapped + Unmapped)", value: "0" },
              ]}
              error={errors.only_for_unmapped ? true : false}
              helperText={errors.only_for_unmapped as string}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={6}>
            <CustomFormLabel>From Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.from_date}
              onChange={(newValue) => {
                setValues({ ...values, from_date: newValue });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomFormLabel>Time</CustomFormLabel>
            <CustomTimePicker
              fullWidth
              value={values.from_time || ""}
              onChange={(newValue) => {
                setValues({ ...values, from_time: newValue });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomFormLabel>To Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.to_date || ""}
              onChange={(newValue) => {
                setValues({ ...values, to_date: newValue });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomFormLabel>Time</CustomFormLabel>
            <CustomTimePicker
              fullWidth
              value={values.to_date || ""}
              onChange={(newValue) => {
                setValues({ ...values, to_time: newValue });
              }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
