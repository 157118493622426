import Avatar from "@mui/material/Avatar";
import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { DataTable } from "../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";

import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../../../../hooks";
import { Button, MenuItem } from "@mui/material";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import {
  clearTaskCancel,
  fetchTasksListAsync,
} from "../../../../../../redux/tasks/tasksActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { ITask } from "../../../../../../redux/tasks/task.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { LeadOppStatus } from "../../../../../../components/LeadOppStatus/LeadOppStatus";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../../../../redux/common/commonActions";
import { task_status } from "../../../../../../constants/constants";
import { RoleBasedCSVExportPlugin } from "../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { BeforeCreateTypes } from "../../../../../tasks/BeforeCreate/BeforeCreate.types";
import { TaskAssignedTo } from "../../../../../tasks/BeforeCreate/TaskAssignedTo";
import { ViewTaskDetails } from "../../../../../tasks/BeforeCreate/ViewTaskDetails/ViewTaskDetails";
import { clearBusinessPolicyRestrutant } from "../../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";

export const BusinessTaskActivity: React.FC = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerPolicyId = data1[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: "all-tasks",
    },
  ];

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.tasks.allTasks
  );

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeCreateTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    BeforeCreateTypes["status"] | "-1"
  >("-1");

  const {
    state: { dateState, searchState, columnsConfig },
    setDateState,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "lastWeek",
      selectedTab: "-1",
    },
  });

  const dispatch = useDispatchWrapper();

  const navigate = useNavigate();
  const fetchList = async () => {
    dispatch(
      fetchTasksListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        dateState.dates,
        searchState,
        customerPolicyId
      )
    );
  };
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearTaskCancel());
    };
  }, []);

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, dateState, status, searchState]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const opportunitesTableProps: IDataTableV2Props = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [
          ...menuItems,
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value={-1}>{"All Status"}</MenuItem>;
                {task_status.map((task) => {
                  return <MenuItem value={task}>{task}</MenuItem>;
                })}
              </CustomSelect>
            ),
          },
        ],
      },
      rightItems: {
        customPlugins: [],
      },
    },

    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              commentBoxTypeId={row.task_code || undefined}
              historyCompData={{
                module_code: row.task_code,
              }}
              onViewClick={() =>
                // setOpenView(row as ITask)
                navigate("/task-view/" + (row as ITask).task_code)
              }
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/task-activity-tab/${customerPolicyId}/tasks/` +
                    row.task_code
                );
              }}
            />
          );
        },
      },
      {
        key: "task_status",
        headerName: "Status",
        fieldName: "task_status",

        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ITask) => {
          return <LeadOppStatus status={row.status} />;
        },
      },
      {
        key: "assigned_to",
        headerName: "Assigned To",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <TaskAssignedTo task={row} onComplete={fetchList} />;
        },
      },
      {
        key: "customer_code",
        headerName: "Code",
        fieldName: "customer_code",
        renderType: DataTableV2RowRenderType.TEXT,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/task-view/" + (row as ITask).task_code}
              label={row.customer_code}
            />
          );
        },
      },

      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ITask) => {
          return (
            <CustomLink
              to={
                `/business-policy/${customerId}/task-activity-tab/${customerPolicyId}/tasks/` +
                row.task_code
              }
              label={`${row.related_to_customer_name}`}
            />
          );
        },
      },

      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },

      {
        key: "task_type",
        headerName: "Transaction",
        fieldName: "task_type",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ITask) => {
          return (
            <CustomLink
              to={
                `/business-policy/${customerId}/task-activity-tab/${customerPolicyId}/tasks/` +
                row.task_code
              }
              label={row.task_type}
            />
          );
        },
      },
      {
        key: "task_sub_type",
        headerName: "Sub-Type",
        fieldName: "task_sub_type",

        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
      },
      {
        key: "branch_code",
        headerName: "Branch",
        fieldName: "branch_code",
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "insurer",
        headerName: "Insurer",
        fieldName: "insurer",

        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "producer_one_code",
        headerName: "Producer",
        fieldName: "producer_one_code",

        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "effective_date",
        headerName: "Effective Date",
        fieldName: "effective_date",
        renderType: DataTableV2RowRenderType.DATE,
      },

      {
        key: "days_left",
        headerName: "Days Left",
        fieldName: "days_left",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "task_created_date",
        headerName: "Date Created",
        fieldName: "task_created_date",
        renderType: DataTableV2RowRenderType.DATE,
      },
      {
        key: "task_due_date",
        headerName: "Due Date",
        fieldName: "task_due_date",
        renderType: DataTableV2RowRenderType.DATE,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
    ],
    rows: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: BeforeCreateTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  const handleClick = () => {
    navigate(
      `/business-policy/${customerId}/task-activity-tab/${customerPolicyId}/tasks`
    );
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_task",
        "status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates]);

  return (
    <PageContainer
      title="business task/activity"
      description="this is business task/activity"
    >
      {/* <IconicMultiColumnCard
      loading={recordCountsLoading !== LoadState.Loaded}
        columns={recordsCounts.map((item) => {
          return {
            heading: capitalizeWords(item.name),
            value: item.cnt,
            colSize: 2,
          };
        })}
      /> */}

      <PageTitleBar
        heading="Business Tracker"
        rightHeading={
          <Button variant="contained" onClick={handleClick}>
            Create Task
          </Button>
        }
      />
      <DataTableV2 {...opportunitesTableProps} />

      {/* {openView && (
        <ViewTaskDetails
          open={true}
          taskDetails={openView}
          onClose={() => setOpenView(null)}
        />
      )} */}
    </PageContainer>
  );
};
