import { Box, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import {
  useDispatchWrapper,
} from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { ILifeInsurancePolicyBasicDetailsProps } from "../LifeInsuranceBasicDetails.types";
import {
  addLifeInsurancePremiumAsync,
  fetchLifeInsurancePremiumAsync,
} from "../../../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailActions";
import { IPolicyCommission } from "../../../../../../../redux/policyCommission/policyCommission.types";
import { api } from "../../../../../../../api/api";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const LifeInsurancePremium: React.FC<
  ILifeInsurancePolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { data, Loading } = useSelector(
    (storeState: IStoreState) => storeState.lifeInsurance.basicDetails.premium
  );
  const dispatch = useDispatchWrapper();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "-1"
  );
  const [list, setList] = React.useState<IPolicyCommission[]>([]);

  const fetchList = async () => {
    let url = "";
    if (status !== "-1") {
      url = "&status=" + status;
    }
    let finalUrl = `/insurance/get-policy-commission?pageNo=${1}&itemPerPage=${50}${url}`;
    const res = await api.get(finalUrl);
    setList(res.data.data);
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        setSaveLoading(true);
        dispatch(
          addLifeInsurancePremiumAsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            () => {
              setSaveLoading(false);
            }
          )
        );
      },
    });

  React.useEffect(() => {
  
      dispatch(fetchLifeInsurancePremiumAsync(customerpolicyId));

  }, []);

  React.useEffect(() => {
    setValues(data);
    setFieldValue(
      "commission_code_percentage",
      list[0]?.commission_percentage_new
    );
  }, [data, list]);

  React.useEffect(() => {
    const value =
      (Number(values?.policy_premium) *
        Number(values?.commission_code_percentage)) /
      100;
    setFieldValue("fyc", value);
  }, [values.commission_code_percentage, values.policy_premium]);

  return (
    <PageLoader loading={Loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Policy Premium</CustomFormLabel>
            <CustomTextField
              name="policy_premium"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.policy_premium}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Payment Plan</CustomFormLabel>
            <CustomTextField
              name="payment_plan"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.payment_plan}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Commission Code (%)</CustomFormLabel>
            <CustomTextField
              name="commission_code_percentage"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.commission_code_percentage}
              disabled
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Bonus %</CustomFormLabel>
            <CustomTextField
              name="bonus_percent"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.bonus_percent}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Bonus Amount</CustomFormLabel>
            <CustomTextField
              name="bonus_amount"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.bonus_amount}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>FYC</CustomFormLabel>
            <CustomTextField
              name="fyc"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.fyc}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Status</CustomFormLabel>
            <ControlledCustomSelect
              name="status"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              displayEmpty
              placeholder="Select One"
              options={[
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
              ].map((data) => {
                return { label: data.label, value: data.value };
              })}
              value={values.status}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.LIFE_AND_FINCANCIAL}
            type="submit"
            disabled={saveLoading}
            variant="contained"
          >
            Save
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
