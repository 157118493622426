import { LoadState } from "../../constants/enums";

export interface IUtilityState {
  list: IUtilityResponse[];
  totalRecords: number;
  loading: LoadState;
}

export interface IUtilityBranchCode {
  producer_code: string[];
  old_branch_code: string | null;
  new_branch_code: string | null;
  status: string;
}

export interface IUtilityCSRCode {
  producer_code: string[];
  branch_code: string[];
  type_of_policy: string[];
  customer_name: string[];
  customer_code: string[];
  csr_code: string;
  status: string;
}

export interface IUtilityProducerCode {
  producer_code: string[];
  branch_code: string[];
  type_of_policy: string[];
  customer_name: string[];
  customer_code: string[];
  new_producer_code: string;
  status: string;
}

export interface IUtilityCustomerCode {
  old_customer_id: string | null;
  new_customer_id: string | null;
}

export interface IUtilityResponse {
  create_ts: string;
  created_by_id: string | null;
  impacted_column_name: string;
  impacted_ids: string;
  impacted_table_name: string;
  insert_ts: string;
  new_value: string;
  old_value: string;
  utility_history_code: string;
  utility_history_id: number;
}

export const initialUtilityState: IUtilityState = {
  list: [],
  loading: LoadState.NotLoaded,
  totalRecords: 0,
};

export const initialUtilityBranchCode: IUtilityBranchCode = {
  new_branch_code: "",
  old_branch_code: "",
  producer_code: [],
  status: "ACTIVE",
};

export const initialUtilityCSRCode: IUtilityCSRCode = {
  producer_code: [],
  branch_code: [],
  type_of_policy: [],
  customer_name: [],
  customer_code: [],
  csr_code: "",
  status: "ACTIVE",
};

export const initialUtilityProducerCode: IUtilityProducerCode = {
  producer_code: [],
  branch_code: [],
  type_of_policy: [],
  customer_name: [],
  customer_code: [],
  new_producer_code: "",
  status: "ACTIVE",
};

export const initialUtilityCustomerCode: IUtilityCustomerCode = {
  old_customer_id: null,
  new_customer_id: null,
}
