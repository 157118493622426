import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Checkbox, Chip, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

const CustomClearIcon = styled(ClearIcon)(({ theme }) => ({
  fontSize: "1.2rem"
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const CustomSelect = styled((props: SelectProps) => (
  <Select {...props} />
))(({ theme }) => ({
  "& .MuiSelect-select": {
    color: "#767e89",
    padding: "9px 13px",
    fontSize: "0.87rem",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    borderColor: `${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"
      }`,
  },
  "& .MuiSelect-select::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
}));

interface ICustomSelectProps extends SelectProps {
  options: { label: number | string | null; value: string | number }[];
  clearable?: boolean;
  onClear?: () => void;
  helperText?: string;
}

export const ControlledCustomSelect: React.FC<ICustomSelectProps> = (props) => {
  const renderPlaceholder = () => props.placeholder;
  const handleClear = () => {
    if (props.onClear) {
      props.onClear();
    }
  };


  const getOptionValue = () => {
    if(props.multiple && Array.isArray(props.value)){
      return props.value
    }
    else if(props.multiple || props.value === "[]"){
      return [];
    }
    else if(props.multiple){
      return [props.value]
    }
   return props.value || ""
  }

  return (
    <>
    <CustomSelect
      {...props}
      multiple={props.multiple}
      value={getOptionValue()}
      renderValue={(selected) => {
        if (selected) {
          return getLabelFromValue(selected, props.options)
        }
        return renderPlaceholder()
      }
      }
      endAdornment={
        props.clearable && props.value ? (
          <InputAdornment position="end" sx={{marginRight: '7px'}}>
            <ClearButton onClick={handleClear}>
              <CustomClearIcon />
            </ClearButton>
          </InputAdornment>
        ) : null
      }
    >
      {props.options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {props.multiple && Array.isArray(props.value) && (
              <Checkbox checked={props.value.indexOf(option.value) > -1} />
            )}
            {option.label}
          </MenuItem>
        );
      })}
    </CustomSelect>
    {props.helperText &&
      <ErrorMessage value={props.helperText}/>
    }
 </>
  );
};

export const ControlledShrinkCustomSelect: React.FC<ICustomSelectProps> = (props) => {
  const renderPlaceholder = () => props.placeholder;
  const handleClear = () => {
    if (props.onClear) {
      props.onClear();
    }
  };


  const getOptionValue = () => {
    if(props.multiple && Array.isArray(props.value)){
      return props.value
    }
    else if(props.multiple || props.value === "[]"){
      return [];
    }
    else if(props.multiple){
      return [props.value]
    }
   return props.value || ""
  }

  return (
    <>
     <FormControl fullWidth>
       <InputLabel id={props.labelId}>{props.label}</InputLabel>
    <Select
      {...props}
      sx={{
        '& .MuiInputLabel-shrink + .MuiFormLabel-root': {
          fontSize: '1.3rem !important',
          fontWeight: 600, // Increase the font size here
       
        },
        '& .MuiInputLabel-shrink + .MuiOutlinedInput-root': {
    
          '& fieldset': {
            legend: {
             
              maxWidth: '100%', // Ensure it does not overflow
              fontSize: '1.05rem',
            
            },
          },
        },
        ...props.sx
      }}
      labelId={props.labelId}
      multiple={props.multiple}
      value={getOptionValue()}
      renderValue={(selected) => {
        if (selected) {
          return getLabelFromValue(selected, props.options)
        }
        return renderPlaceholder()
      }
      }
      endAdornment={
        props.clearable && props.value ? (
          <InputAdornment position="end" sx={{marginRight: '7px'}}>
            <ClearButton onClick={handleClear}>
              <CustomClearIcon />
            </ClearButton>
          </InputAdornment>
        ) : null
      }
    >
      {props.options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {props.multiple && Array.isArray(props.value) && (
              <Checkbox checked={props.value.indexOf(option.value) > -1} />
            )}
            {option.label}
          </MenuItem>
        );
      })}
    </Select>
    {props.helperText &&
      <ErrorMessage value={props.helperText}/>
    }
    </FormControl>
 </>
  );
};

const getLabelFromValue = (
  value: any,
  options: ICustomSelectProps["options"]
) => {
  if (Array.isArray(value)) {
    const data = options.filter((option) => {
     if(value.includes(option.value)){
      return true;
     }
     return false
    });
    return  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    {data.map(x=>x.label).map((value) => (
      <Chip key={value} label={value} size="small" />
    ))}
  </Box>
 // return value;
    
    
  }
  const option = options.find((option) => option.value === value);
  return option ? option.label : "";
};
